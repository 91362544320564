import Layout from "../../components/layouts/base.jsx";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  Text,
  ModalOverlay,
  IconButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Badge,
  Link,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { axiosInstance as axios } from "../../lib/axios.js";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import DeleteManyDialog from "../../components/admin/DeleteAll.jsx";

const CountrySchema = Yup.object().shape({
  name: Yup.string().required("Country name is Mandatory"),
  isoCode: Yup.string().required("ISO Code is Mandatory"),
});

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      height: "7vh",
    },
  },
};

export default function Countries() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [refetch, setrefetch] = useState(false);
  const [activeCountry, setActiveCountry] = useState({});

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/countries/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/countries/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(",");
    } else if (s === "all") {
      ids = "all";
    }
    const _res = await axios.delete(`/countries/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText("");
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/countries/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/countries/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  const deleteCategory = async (_) => {
    const _res = await axios.delete(`/countries/${_._id}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const _openeditModel = (_data) => {
    onOpen();
    setActiveCountry({ ..._data });
  };

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={"flex"} gap={4} p={2}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={"120px"}
          colorScheme={"red"}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
        width={"300px"}
      />
      <IconButton
        colorScheme={"linkedin"}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const BulkUpload = async (file) => {
    if (!file)
      return toast({
        description: "Please Select a file",
        status: "error",
        position: "bottom-right",
      });

    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post("/countries/bulk", formData);

    if (res.data.ok) {
      toast({
        description: res.data.message,
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    }

    document.getElementById("file").value = "";
  };

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Iso Code",
      selector: (row) => row.isoCode,
    },
    {
      name: "Actions",
      cell: (_r) => (
        <Flex gap={2}>
          <Button colorScheme={"red"} onClick={() => deleteCategory(_r)}>
            Delete
          </Button>
          <Button colorScheme={"blue"} onClick={() => _openeditModel(_r)}>
            Edit
          </Button>
        </Flex>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        fetchData(1);
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}></Text>
        <DeleteManyDialog
          title={"Countries"}
          onDelete={() => handleMultipleDelete("all")}
        />
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              handleSearch(e.target.value);
            } else if (!e.target.value) {
              fetchData(1);
            }
            // get the input with id "search" and focus after 400 ms
            setTimeout(() => {
              document.getElementById("search").focus();
            }, 200);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <>
      <input
        type={"file"}
        id={"file"}
        accept={
          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        }
        onChange={(e) => {
          BulkUpload(e.target.files[0]);
        }}
        style={{ display: "none" }}
      />
      <Layout>
        <Flex
          as={"main"}
          w={"100%"}
          bg={useColorModeValue("gray.50", "gray.800")}
        >
          <Box
            bg={useColorModeValue("white", "gray.700")}
            px={6}
            m={6}
            rounded={"xl"}
            w={"30%"}
          >
            <Text as={"h1"} fontSize={"2xl"} pb={"4"}>
              Managing Countries
            </Text>
            <Formik
              initialValues={{
                name: "",
                isoCode: "",
              }}
              onSubmit={async ({ name, isoCode }, { resetForm }) => {
                const res = await axios.post("/countries", {
                  countryName: name,
                  isoCode,
                });

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: "success",
                    position: "bottom-right",
                  });
                  resetForm();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: "error",
                    position: "bottom-right",
                  });
                }
              }}
              validationSchema={CountrySchema}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="name"
                      isInvalid={errors.name}
                      onChange={(e) => {
                        setFieldValue("seoName", e.target.value);
                      }}
                    >
                      <FormLabel>Name of the Country</FormLabel>
                      <Field as={Input} type="text" name="name" />
                      {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="isoCode"
                      isInvalid={touched.isoCode && errors.isoCode}
                    >
                      <FormLabel>
                        ISO Code <Badge colorScheme="green">2 Characters</Badge>{" "}
                        Example:{" "}
                        <Link
                          href="https://www.iban.com/country-codes"
                          isExternal
                        >
                          List of ISO Codes
                        </Link>
                      </FormLabel>
                      <Field as={Input} type="text" name="isoCode" />
                      {errors.isoCode && (
                        <FormErrorMessage>{errors.isoCode}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <Button
                    bg={"blue.400"}
                    mt={4}
                    type={"submit"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    bg={"blue.400"}
                    mt={4}
                    mx={4}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    onClick={() => {
                      document.getElementById("file").value = "";
                      document.getElementById("file").click();
                    }}
                  >
                    Bulk Upload
                  </Button>

                  <Badge my={3} variant="subtle" colorScheme="green">
                    <a
                      href="/assets/sample/countrylist.xlsx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sample Excel File
                    </a>
                  </Badge>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            rounded={"xl"}
            px={4}
            w={"70%"}
            m={6}
            backgroundColor={"#FFFFFF"}
            border={"1px"}
            borderColor={"gray.200"}
          >
            <DataTable
              striped
              columns={columns}
              data={data.data}
              paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              selectableRows
              selectedRows={currentSelectedRows}
              onSelectedRowsChange={handleRowSelected}
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              customStyles={customStyles}
              clearSelectedRows={toggleCleared}
            />
          </Box>
        </Flex>
      </Layout>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setActiveCountry({});
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"}>
            <Formik
              initialValues={{
                _name: activeCountry.name,
              }}
              onSubmit={async ({ _name }, { resetForm }) => {
                const res = await axios.put(`/countries/${activeCountry._id}`, {
                  countryName: _name,
                });

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: "success",
                    position: "bottom-right",
                  });
                  resetForm();
                  onClose();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: "error",
                    position: "bottom-right",
                  });
                }
              }}
            >
              {({ errors: _e, touched: _t, resetForm }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl id="_name" isInvalid={_e._name}>
                      <FormLabel>Name</FormLabel>
                      <Field as={Input} type="text" name="_name" />
                      {_t._name && _e._name && (
                        <FormErrorMessage>{_e._name}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
