import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { axiosInstance } from "../../../lib/axios";

export const OrgList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orgs, setOrgs] = useState([]);

  const getOrgs = async () => {
    try {
      const { data } = await axiosInstance.get("/referral/organization");
      setOrgs(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = () => {
    onOpen();
    getOrgs();
  };

  return (
    <>
      <Button onClick={openModal} size={"sm"}>
        Org. List
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Referral Organization List</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="simple" borderWidth="1px" mb={4}>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Sales Persons</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orgs.map((org) => (
                    <Tr key={org._id}>
                      <Td>{org.name}</Td>
                      <Td>{org.salesPersonCount}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
