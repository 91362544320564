import { Box, Flex } from "@chakra-ui/react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";

import { TipTapMenuBar } from "./TipTapMenuBar";
import "./editor.css";

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  Highlight,
  Link,
  Typography,
  Underline,
  StarterKit,
];

const Tiptap = ({ onChange, content = "" }) => {
  const editor = useEditor({
    extensions,
    content,
  });

  if (!editor) {
    return null;
  }

  editor.on("update", ({ editor }) => {
    onChange(editor.getHTML());
  });

  return (
    <Box
      p={2}
      flex={1}
      display="flex"
      flexDirection="column"
      flexGrow={1}
      style={{ minHeight: 0 }}
      borderWidth="1px"
      maxH="calc(100vh - 400px)"
    >
      <TipTapMenuBar editor={editor} />
      <Flex
        className="tiptap-editor"
        flexDirection="column"
        flexGrow={1}
        overflowY="auto"
        overflowX="auto"
        rounded="md"
      >
        <EditorContent
          className="editor-wrapper"
          editor={editor}
          onClick={() => editor.commands.focus()}
        />
      </Flex>
    </Box>
  );
};

export default Tiptap;
