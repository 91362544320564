import Layout from "../../components/layouts/base.jsx";
import { useState, useMemo, useRef, useEffect } from "react";

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  IconButton,
  Grid,
  GridItem,
  Badge,
  Switch,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../lib/axios.js";
import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { MultiSelect } from "primereact/multiselect";
import { RxCross2 } from "react-icons/rx";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "6vw",
      height: "7vh",
      color: "black",
    },
  },
};

const PlansShcema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  modulesAllowed: Yup.array().of(Yup.string().required("Module is Required")),
  month: Yup.number().required("Monthly Price is Required"),
  monthlyTrailDays: Yup.number(),
  year: Yup.number().required("Yearly Price is Required"),
  yearlyTrailDays: Yup.number(),
  lawsDownloadLimit: Yup.number().required("Download Limit is Required"),
  regulationsDownloadLimit: Yup.number().required("Download Limit is Required"),
  contractsDownloadLimit: Yup.number().required("Download Limit is Required"),
  chatsPerDay: Yup.number().required("Chat Create Limit is Required"),
  isFeatured: Yup.boolean(),
  features: Yup.array().of(Yup.string().required("Feature name is required")),
  trialAvailable: Yup.boolean().default(false),
});

const moduleOptions = [
  {
    name: "Laws and Regulations",
    value: "law",
  },
  {
    name: "Contracts and Clauses",
    value: "contract",
  },
];

export default function Lawsandregulatory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/plans/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/plans/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const [selectedPlan, setselectedPlan] = useState({});

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: plansisOpen, onClose: plansonClose } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };

  const deletePlan = async (_r) => {
    const _res = await axios.delete("/plans/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editPlans = async (_r) => {
    setCurrentOne({
      edit: true,
      data: {
        ..._r,
      },
    });
    onOpen();
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editPlans,
      colorScheme: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deletePlan,
      colorScheme: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      width: "4%",
    },
    {
      name: "Name",
      cell: (_r) => (
        <Text
          onClick={() => {
            setselectedPlan(_r);
          }}
          whiteSpace={"nowrap"}
        >
          {_r.name}
        </Text>
      ),
    },
    {
      name: "Featured",
      cell: (_r) => {
        return (
          <Badge
            p={2}
            rounded={"md"}
            colorScheme={_r.isFeatured ? "green" : "red"}
          >
            {_r.isFeatured ? "Yes" : "No"}
          </Badge>
        );
      },
    },
    {
      name: "Trial Available",
      cell: (_r) => {
        return (
          <Badge
            p={2}
            rounded={"md"}
            colorScheme={_r.trialAvailable ? "green" : "red"}
          >
            {_r.trialAvailable ? "Yes" : "No"}
          </Badge>
        );
      },
    },

    {
      name: "Actions",
      cell: (_r) => (
        <Box position={"fixed"} zIndex={10}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      colorScheme={link.colorScheme}
                      onClick={() => link.onClick(_r)}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const addFeature = (values, setFieldValue) => {
    setFieldValue("features", [...values.features, ""]);
  };

  const removeFeature = (index, values, setFieldValue) => {
    const newFeatures = values.features.filter((_, idx) => idx !== index);
    setFieldValue("features", newFeatures);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Plans
        </Text>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={"linkedin"}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    const url = currentOne.edit ? "/plans/" + currentOne.data._id : "/plans";

    const _res = currentOne.edit
      ? await axios.put(url, values)
      : await axios.post(url, values);

    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      onClose();
      fetch();
      setCurrentOne({ edit: false, data: {} });
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          size={"6xl"}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? "Add New Plan" : "Edit"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Box>
                <Formik
                  initialValues={{
                    name: currentOne.edit ? currentOne.data.name : "",
                    month: currentOne.edit ? currentOne.data.month : "",
                    monthlyTrailDays: currentOne.edit
                      ? currentOne.data.monthlyTrailDays
                      : 0,
                    year: currentOne.edit ? currentOne.data.year : "",
                    yearlyTrailDays: currentOne.edit
                      ? currentOne.data.yearlyTrailDays
                      : 0,
                    isFeatured: currentOne.edit
                      ? currentOne.data.isFeatured
                      : false,
                    features: currentOne.edit ? currentOne.data.features : [""],
                    lawsDownloadLimit: currentOne.edit
                      ? currentOne.data.lawsDownloadLimit
                      : 0,
                    regulationsDownloadLimit: currentOne.edit
                      ? currentOne.data.regulationsDownloadLimit
                      : 0,
                    contractsDownloadLimit: currentOne.edit
                      ? currentOne.data.contractsDownloadLimit
                      : 0,
                    chatsPerDay: currentOne.edit
                      ? currentOne.data.chatsPerDay
                      : 0,
                    modulesAllowed: currentOne.edit
                      ? currentOne.data.modulesAllowed
                      : [],
                    trialAvailable: currentOne.edit
                      ? currentOne.data.trialAvailable
                      : false,
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={PlansShcema}
                >
                  {({
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    validateForm,
                  }) => (
                    <Form>
                      <Stack spacing={4}>
                        <Flex
                          gap={10}
                          justifyContent={"space-between"}
                          alignItems={"start"}
                        >
                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="name"
                              isInvalid={touched.name && errors.name}
                            >
                              <FormLabel>Name of the Plan</FormLabel>
                              <Field
                                as={Input}
                                type="text"
                                name="name"
                                placeholder={"Name of the Plan"}
                              />
                              {errors.name && (
                                <FormErrorMessage>
                                  {errors.name}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                              <GridItem w="100%">
                                <FormControl id="month">
                                  <FormLabel>Monthly Price</FormLabel>
                                  <Field
                                    as={Input}
                                    type="number"
                                    name="month"
                                    min={0}
                                    placeholder={"Monthly Price"}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem w="100%">
                                <FormControl id="year">
                                  <FormLabel>Yearly Price</FormLabel>
                                  <Field
                                    min={0}
                                    as={Input}
                                    type="number"
                                    name="year"
                                    placeholder={"Yearly Price"}
                                  />
                                </FormControl>
                              </GridItem>

                              <GridItem w="100%">
                                <FormControl
                                  id="monthlyTrailDays"
                                  isDisabled={!values.trialAvailable}
                                >
                                  <FormLabel>Monthly Trial Days</FormLabel>
                                  <Field
                                    as={Input}
                                    min={0}
                                    type="number"
                                    name="monthlyTrailDays"
                                    placeholder={"Monthly Trial Days"}
                                  />
                                </FormControl>
                              </GridItem>

                              <GridItem w="100%">
                                <FormControl
                                  isDisabled={!values.trialAvailable}
                                  id="yearlyTrailDays"
                                >
                                  <FormLabel>Yearly Trial Days</FormLabel>
                                  <Field
                                    as={Input}
                                    type="number"
                                    min={0}
                                    name="yearlyTrailDays"
                                    placeholder={"Yearly Trial Days"}
                                  />
                                </FormControl>
                              </GridItem>

                              {/* <GridItem w="100%">
                                <FormControl id="quarter">
                                  <FormLabel>Quarterly Price</FormLabel>
                                  <Field
                                    as={Input}
                                    type="number"
                                    name="quarter"
                                    placeholder={"Quarterly Price"}
                                  />
                                </FormControl>
                              </GridItem> */}

                              {/* <GridItem w="100%">
                                <FormControl id="half_year">
                                  <FormLabel>Half Yearly Price</FormLabel>
                                  <Field
                                    as={Input}
                                    type="number"
                                    name="half_year"
                                    placeholder={"Half Yearly Price"}
                                  />
                                </FormControl>
                              </GridItem> */}
                            </Grid>

                            <FormControl
                              id="trialAvailable"
                              isInvalid={
                                touched.trialAvailable && errors.trialAvailable
                              }
                              name={"trialAvailable"}
                            >
                              <FormLabel>Trial Available</FormLabel>
                              <Switch
                                id="trialAvailable"
                                name="trialAvailable"
                                isChecked={values.trialAvailable}
                                onChange={(e) => {
                                  setFieldValue(
                                    "trialAvailable",
                                    e.target.checked
                                  );
                                }}
                              />

                              {errors.trialAvailable && (
                                <FormErrorMessage>
                                  {errors.trialAvailable}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <Stack spacing={8} width={"100%"}>
                              <Text as={"h3"}>Features</Text>
                              {values.features.map((feature, index) => (
                                <Flex
                                  key={index}
                                  gap={2}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <FormControl
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    id={`features[${index}]`}
                                    gap={2}
                                    isInvalid={
                                      touched.features &&
                                      touched.features[index] &&
                                      errors.features &&
                                      errors.features[index]
                                    }
                                  >
                                    <Field
                                      as={Input}
                                      name={`features[${index}]`}
                                    />
                                    <Button
                                      onClick={() =>
                                        removeFeature(
                                          index,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                      colorScheme="red"
                                    >
                                      <RxCross2 />
                                    </Button>
                                  </FormControl>
                                  {errors.features &&
                                    errors.features[index] && (
                                      <FormErrorMessage>
                                        {errors.features[index]}
                                      </FormErrorMessage>
                                    )}
                                </Flex>
                              ))}
                              <Button
                                onClick={() =>
                                  addFeature(values, setFieldValue)
                                }
                              >
                                Add Feature
                              </Button>
                            </Stack>
                          </Stack>

                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="modulesAllowed"
                              isInvalid={
                                touched.modulesAllowed && errors.modulesAllowed
                              }
                            >
                              <FormLabel>Modules</FormLabel>
                              <Field
                                as={MultiSelect}
                                value={values.modulesAllowed}
                                options={moduleOptions}
                                onChange={(e) => {
                                  console.log(e);
                                  setFieldValue("modulesAllowed", e.value);
                                }}
                                optionValue="value"
                                optionLabel="name"
                                placeholder="Select Modules"
                                maxSelectedLabels={3}
                                name="modulesAllowed"
                              />

                              {errors.modulesAllowed && (
                                <FormErrorMessage>
                                  {errors.modulesAllowed}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="isFeatured"
                              isInvalid={
                                touched.isFeatured && errors.isFeatured
                              }
                              name={"isFeatured"}
                            >
                              <FormLabel>Featured Plan</FormLabel>
                              <Field as={Select} name="isFeatured">
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </Field>
                              {errors.isFeatured && (
                                <FormErrorMessage>
                                  {errors.isFeatured}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl
                              id="chatsPerDay"
                              isInvalid={
                                touched.chatsPerDay && errors.chatsPerDay
                              }
                            >
                              <FormLabel>Chats Per day</FormLabel>
                              <Field
                                as={Input}
                                type={"number"}
                                placeholder={"Download Limit"}
                                name="chatsPerDay"
                              />
                              {errors.chatsPerDay && (
                                <FormErrorMessage>
                                  {errors.chatsPerDay}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <Flex flexDir={"column"} gap={4}>
                              <FormControl
                                id="lawsDownloadLimit"
                                isInvalid={
                                  touched.lawsDownloadLimit &&
                                  errors.lawsDownloadLimit
                                }
                              >
                                <FormLabel>
                                  Law Download Limit (per day)
                                </FormLabel>
                                <Field
                                  as={Input}
                                  type={"number"}
                                  placeholder={"Law Download Limit"}
                                  name="lawsDownloadLimit"
                                />
                                {errors.lawsDownloadLimit && (
                                  <FormErrorMessage>
                                    {errors.lawsDownloadLimit}
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                              {/* regulation download limit */}
                              <FormControl
                                id="regulationsDownloadLimit"
                                isInvalid={
                                  touched.regulationsDownloadLimit &&
                                  errors.regulationsDownloadLimit
                                }
                              >
                                <FormLabel>
                                  Regulation Downloads (per day)
                                </FormLabel>
                                <Field
                                  as={Input}
                                  type={"number"}
                                  placeholder={"Regulation Download Limit"}
                                  name="regulationsDownloadLimit"
                                />
                                {errors.regulationsDownloadLimit && (
                                  <FormErrorMessage>
                                    {errors.regulationsDownloadLimit}
                                  </FormErrorMessage>
                                )}
                              </FormControl>

                              {/* contracts download limit */}
                              <FormControl
                                id="contractsDownloadLimit"
                                isInvalid={
                                  touched.contractsDownloadLimit &&
                                  errors.contractsDownloadLimit
                                }
                              >
                                <FormLabel>
                                  Contracts Download Limit (per day)
                                </FormLabel>
                                <Field
                                  as={Input}
                                  type={"number"}
                                  placeholder={"Contracts Download Limit"}
                                  name="contractsDownloadLimit"
                                />
                                {errors.contractsDownloadLimit && (
                                  <FormErrorMessage>
                                    {errors.contractsDownloadLimit}
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                            </Flex>
                          </Stack>
                        </Flex>

                        <Box
                          display={"flex"}
                          gap={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Button
                            colorScheme={"linkedin"}
                            type={"submit"}
                            color={"white"}
                            _hover={{
                              bg: "blue.500",
                            }}
                            mb={"8"}
                          >
                            {currentOne.edit ? "Update" : "Create"}
                          </Button>

                          <Button
                            colorScheme={"red"}
                            type={"button"}
                            onClick={() => {
                              onClose();
                              setCurrentOne({
                                edit: false,
                                data: {},
                              });
                            }}
                            color={"white"}
                            _hover={{
                              bg: "red.500",
                            }}
                            mb={"8"}
                          >
                            Close
                          </Button>
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Display the data of model */}

        <Modal
          initialFocusRef={initialRef}
          isOpen={plansisOpen}
          onClose={() => {
            plansonClose();
            setselectedPlan({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Text as={"h3"}>Name</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.name}
              </Text>
              <Text as={"h3"}>Type</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.type}
              </Text>
              <Text as={"h3"}>Country</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.country}
              </Text>
              <Text as={"h3"}>Description</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.description}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme={"linkedin"}
                onClick={() => {
                  plansonClose();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          // m={10}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            pagination
            // paginationResetDefaultPage={resetPaginationToggle}
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
