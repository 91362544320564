import { useState } from "react";
import { Input, Textarea, Button, useToast, Flex } from "@chakra-ui/react";
import { axiosInstance as axios } from "../../../../lib/axios";

const UserEmail = ({ user }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const sendEmail = async () => {
    try {
      if (!subject || !message) {
        toast({
          description: "Please enter subject and message.",
          status: "error",
          position: "top",
        });
        return;
      }

      setLoading(true);
      const res = await axios.post("/email/send", {
        to: user.email,
        subject,
        message,
      });

      if (res.status === 200) {
        toast({
          description: "Email sent successfully.",
          status: "success",
          position: "top",
        });
        setSubject("");
        setMessage("");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({
        description: "Failed to send email. Please try again.",
        status: "error",
        position: "top",
      });
    }
  };

  return (
    <Flex direction="column" gap={4} overflow="hidden">
      <Input
        placeholder="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <Textarea
        placeholder="Message"
        value={message}
        rows={10}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button
        colorScheme="blue"
        onClick={sendEmail}
        isLoading={loading}
        loadingText="Sending..."
      >
        Send Email
      </Button>
    </Flex>
  );
};

export default UserEmail;
