import { useEffect, useState } from "react";
import mammoth from "mammoth/mammoth.browser";
import { Box } from "@chakra-ui/react";

const PreviewDocx = ({ url }) => {
  const [document, setDocument] = useState("");

  useEffect(() => {
    if (!url) {
      setDocument("URL not provided");
      return;
    }

    const fetchDocx = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setDocument(result.value);
      } catch (error) {
        setDocument(`Error fetching document`);
      }
    };

    fetchDocx();
  }, [url]);

  return (
    <Box bg="white" fontWeight="normal" fontSize="14px" lineHeight="1.5">
      {document ? (
        <Box
          dangerouslySetInnerHTML={{ __html: document }}
          p="20px"
          textAlign="justify"
        />
      ) : null}
    </Box>
  );
};

export default PreviewDocx;
