import Layout from "../../components/layouts/base.jsx";
import { useState, useMemo, useRef, useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  IconButton,
  Textarea,
} from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { ListItem, UnorderedList } from "@chakra-ui/react";
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";

import { axiosInstance as axios } from "../../lib/axios.js";
import Editor from "../../components/admin/Editor.jsx";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "10vw",
      height: "7vh",
      color: "black",
    },
  },
};

const careerValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  location: Yup.string().required("Location is required"),
  qualifications: Yup.array().of(
    Yup.string().required("Qualification is required")
  ),
  responsibilities: Yup.array().of(
    Yup.string().required("Responsibility is required")
  ),
  applicationDeadline: Yup.string().required(
    "Application Deadline is required"
  ),
});

export default function CareerPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setrefetch] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(`/career/`).then((res) => res.data);

    if (res.data.length === 0) return setLoading(false);

    setData(res.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });
  const [selectedFaq, setSelectedFaq] = useState({});

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };
  const deleteCareer = async (_r) => {
    const _res = await axios.delete("/career/" + _r._id);

    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editCareer = (_r) => {
    const { applicationDeadline, ...dto } = _r;

    const fixedData = applicationDeadline.split("T")[0];
    setCurrentOne({
      edit: true,
      data: {
        ...dto,
        applicationDeadline: fixedData,
      },
    });
    onOpen();
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editCareer,
      colorSchema: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deleteCareer,
      colorSchema: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Job Title",
      cell: (row) => (
        <Text
          onClick={() => {
            setSelectedFaq(row);
            caconOpen();
          }}
          _hover={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={"#00A0DC"}
        >
          {row.title}
        </Text>
      ),
    },
    {
      name: "Location",
      selector: (row) => row.location,
    },
    {
      name: "Actions",
      cell: (_r) => (
        <Box position={"fixed"} zIndex={10}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      onClick={() => link.onClick(_r)}
                      colorScheme={link.colorSchema}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Career Manager
        </Text>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={"linkedin"}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, []);

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Modal
          size={"3xl"}
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedFaq({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody p={4} display={"flex"} flexDirection={"column"} gap={4}>
              {selectedFaq?.title ? (
                <Grid templateColumns="repeat(1, 1fr)" gap={4} fontWeight={500}>
                  <GridItem colSpan={1}>
                    <Text fontSize="2xl">Title: {selectedFaq.title}</Text>
                    <Text fontSize="lg">Location: {selectedFaq.location}</Text>
                    <Text fontSize="lg">
                      Application Deadline:{" "}
                      {dayjs(selectedFaq.applicationDeadline).format(
                        "DD/MM/YYYY"
                      )}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text fontSize="2xl">Qualifications</Text>
                    <UnorderedList>
                      {selectedFaq.qualifications.map(
                        (qualification, index) => (
                          <ListItem key={index}>{qualification}</ListItem>
                        )
                      )}
                    </UnorderedList>
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Text fontSize="2xl">Responsibilities</Text>

                    <UnorderedList>
                      {selectedFaq.responsibilities.map(
                        (responsibility, index) => (
                          <ListItem key={index}>{responsibility}</ListItem>
                        )
                      )}
                    </UnorderedList>
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Text fontSize="2xl">Description</Text>
                    <Text fontSize="lg">{selectedFaq.description}</Text>
                  </GridItem>
                </Grid>
              ) : null}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={"linkedin"} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          initialFocusRef={initialRef}
          size={"3xl"}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setCurrentOne({ edit: false, data: {} });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? "Add New Faq" : "Edit"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody display={"flex"} flexDirection={"row"} gap={10}>
              <Box w={"100%"}>
                <Formik
                  initialValues={{
                    title: currentOne.data.title || "",
                    description: currentOne.data.description || "",
                    location: currentOne.data.location || "",
                    qualifications: currentOne.data.qualifications || [],
                    responsibilities: currentOne.data.responsibilities || [],
                    applicationDeadline:
                      currentOne.data.applicationDeadline || "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    const url = currentOne.edit
                      ? `/career/update/${currentOne.data._id}`
                      : "/career/create";

                    const _res = currentOne.edit
                      ? await axios.put(url, values)
                      : await axios.post(url, values);

                    if (_res.data.ok) {
                      toast({
                        description: _res.data.message,
                        status: "success",
                        position: "bottom-right",
                      });
                      resetForm();
                      onClose();
                      fetch();
                      setCurrentOne({ edit: false, data: {} });
                    } else {
                      toast({
                        description: _res.data.message,
                        status: "error",
                        position: "bottom-right",
                      });
                    }
                  }}
                  validationSchema={careerValidationSchema}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                      <Stack spacing={4}>
                        <FormControl
                          id="title"
                          isInvalid={touched.title && errors.title}
                        >
                          <FormLabel>
                            Title
                            <span
                              style={{
                                color: "red",
                                display: "inline",
                                marginLeft: "2px",
                              }}
                            >
                              *
                            </span>
                          </FormLabel>
                          <Field as={Input} type="text" name="title" />
                          {errors.title && (
                            <FormErrorMessage>{errors.title}</FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="location"
                          isInvalid={touched.location && errors.location}
                        >
                          <FormLabel>
                            Location
                            <span
                              style={{
                                color: "red",
                                display: "inline",
                                marginLeft: "2px",
                              }}
                            >
                              *
                            </span>
                          </FormLabel>
                          <Field as={Input} type="text" name="location" />
                          {errors.location && (
                            <FormErrorMessage>
                              {errors.location}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="qualifications"
                          isInvalid={
                            touched.qualifications && errors.qualifications
                          }
                        >
                          <FormLabel>
                            Qualifications
                            <span
                              style={{
                                color: "red",
                                display: "inline",
                                marginLeft: "2px",
                              }}
                            >
                              *
                            </span>
                          </FormLabel>
                          <FieldArray
                            name="qualifications"
                            render={(arrayHelpers) => (
                              <div>
                                {values.qualifications &&
                                values.qualifications.length > 0 ? (
                                  values.qualifications.map(
                                    (qualification, index) => (
                                      <div
                                        key={index}
                                        style={{ marginBottom: "5px" }}
                                      >
                                        <Field
                                          as={Input}
                                          name={`qualifications[${index}]`}
                                        />
                                        <Flex mt={2}>
                                          <Button
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            style={{ marginLeft: "5px" }}
                                          >
                                            -
                                          </Button>
                                          {index ===
                                            values.qualifications.length -
                                              1 && (
                                            <Button
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.insert(
                                                  index + 1,
                                                  ""
                                                )
                                              }
                                              style={{ marginLeft: "5px" }}
                                            >
                                              +
                                            </Button>
                                          )}
                                        </Flex>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <Button
                                    type="button"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    Add Qualification
                                  </Button>
                                )}
                              </div>
                            )}
                          />
                          {touched.qualifications &&
                            errors.qualifications &&
                            typeof errors.qualifications === "string" && (
                              <FormErrorMessage>
                                {errors.qualifications}
                              </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl
                          id="responsibilities"
                          isInvalid={
                            touched.responsibilities && errors.responsibilities
                          }
                        >
                          <FormLabel>
                            Responsibilities
                            <span
                              style={{
                                color: "red",
                                display: "inline",
                                marginLeft: "2px",
                              }}
                            >
                              *
                            </span>
                          </FormLabel>
                          <FieldArray
                            name="responsibilities"
                            render={(arrayHelpers) => (
                              <div>
                                {values.responsibilities &&
                                values.responsibilities.length > 0 ? (
                                  values.responsibilities.map(
                                    (responsibility, index) => (
                                      <div
                                        key={index}
                                        style={{ marginBottom: "5px" }}
                                      >
                                        <Field
                                          as={Input}
                                          name={`responsibilities[${index}]`}
                                        />
                                        <Button
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          style={{ marginLeft: "5px" }}
                                        >
                                          -
                                        </Button>
                                        {index ===
                                          values.responsibilities.length -
                                            1 && (
                                          <Button
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.insert(index + 1, "")
                                            }
                                            style={{ marginLeft: "5px" }}
                                          >
                                            +
                                          </Button>
                                        )}
                                      </div>
                                    )
                                  )
                                ) : (
                                  <Button
                                    type="button"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    Add Responsibility
                                  </Button>
                                )}
                              </div>
                            )}
                          />
                          {touched.responsibilities &&
                            errors.responsibilities &&
                            typeof errors.responsibilities === "string" && (
                              <FormErrorMessage>
                                {errors.responsibilities}
                              </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl
                          id="applicationDeadline"
                          isInvalid={
                            touched.applicationDeadline &&
                            errors.applicationDeadline
                          }
                        >
                          <FormLabel>
                            Application Deadline
                            <span
                              style={{
                                color: "red",
                                display: "inline",
                                marginLeft: "2px",
                              }}
                            >
                              *
                            </span>
                          </FormLabel>
                          <Field
                            as={Input}
                            type="date"
                            name="applicationDeadline"
                          />
                          {errors.applicationDeadline && (
                            <FormErrorMessage>
                              {errors.applicationDeadline}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="description"
                          isInvalid={touched.description && errors.description}
                          overflow={"hidden"}
                        >
                          <FormLabel>
                            Description
                            <span
                              style={{
                                color: "red",
                                display: "inline",
                                marginLeft: "2px",
                              }}
                            >
                              *
                            </span>
                          </FormLabel>
                          <Field
                            as={Editor}
                            name="description"
                            setEditorText={(e) =>
                              setFieldValue("description", e)
                            }
                            editorText={values.description}
                          />
                          {errors.description && (
                            <FormErrorMessage>
                              {errors.description}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <Stack spacing={10}>
                          <Button
                            colorScheme={"linkedin"}
                            type={"submit"}
                            color={"white"}
                            _hover={{ bg: "blue.500" }}
                            mb={"8"}
                          >
                            {currentOne.edit ? "Update" : "Create"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
