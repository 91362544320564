import { Box, Button } from "@chakra-ui/react";

const UserActions = ({ user, onDelete }) => (
  <Box display={"flex"} gap={2}>
    <Button colorScheme={"red"} onClick={() => onDelete(user)} size="sm">
      Delete
    </Button>
  </Box>
);

export default UserActions;
