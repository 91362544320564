import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { axiosInstance as axios } from "../../lib/axios";
import { deletevalue, get, save } from "../../common/storage";
import { useToast } from "@chakra-ui/react";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SigninSchema = Yup.object().shape({
  password: Yup.string().required("Password Required"),
  email: Yup.string()
    .email("Invalid email! Please Check Your Email Id")
    .required("Email Required"),
});

export default function SignIn({ role }) {
  const toast = useToast();

  const navigate = useNavigate();

  const { setUser, setLoggedIn, setRole } = useGlobalContext();

  useEffect(() => {
    // focus email input on load
    document.getElementById("email").focus();
  }, []);

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.50")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("gray.100", "gray.200")}
          boxShadow={"lg"}
          p={8}
          pb={12}
        >
          {role === "user" ? (
            <Stack align={"center"}>
              <Heading
                fontSize={"4xl"}
                textAlign={"center"}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Linkinlegal
              </Heading>
              <Heading fontSize={"4xl"}>Sign in to your account</Heading>
              <Text fontSize={"lg"} color={"gray.600"}>
                to enjoy all of our cool{" "}
                <Link color={"blue.400"}>features</Link> ✌️
              </Text>
            </Stack>
          ) : (
            <Stack align={"center"} pb={12}>
              <Heading fontSize={"4xl"}>Admin Panel</Heading>
            </Stack>
          )}

          <Formik
            initialValues={{
              email: "",
              password: "",
              rememberme: false,
            }}
            onSubmit={async (values) => {
              const url = role === "admin" ? "/admin/login" : "/user/login";
              const res = await axios.post(url, {
                ...values,
              });

              try {
                if (res.status === 200) {
                  let { token, message } = res.data;

                  if (role === "admin") {
                    if (get("usertoken")) deletevalue("usertoken");
                    save("admintoken", token);
                    save("role", "admin");
                    navigate("/admin/user-managment");

                    setRole("admin");

                    toast({
                      description: message,
                      status: "success",
                      position: "bottom-right",
                    });
                  } else if (role === "user") {
                    if (get("admintoken")) deletevalue("admintoken");
                    save("usertoken", token);
                    save("role", "user");

                    setRole("user");

                    navigate("/");
                    toast({
                      description: message,
                      status: "success",
                      position: "bottom-right",
                    });
                  }

                  setLoggedIn(true);
                  setUser(res.data);
                  save("loggedIn", true);
                  save("user", res.data);
                } else {
                  toast({
                    description: res.data.message,
                    status: "error",
                    position: "bottom-right",
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }}
            validationSchema={SigninSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <Stack spacing={4}>
                  <FormControl
                    id="email"
                    isInvalid={errors.email && touched.email}
                  >
                    <FormLabel>Email address</FormLabel>
                    <Field
                      as={Input}
                      type="email"
                      name="email"
                      id="email"
                      tabIndex={1}
                    />
                    {errors.email && touched.email && (
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    id="password"
                    isInvalid={errors.password && touched.password}
                  >
                    <FormLabel>Password</FormLabel>
                    <Field
                      as={Input}
                      type="password"
                      name="password"
                      id="password"
                      tabIndex={2}
                    />
                    {errors.password && touched.password && (
                      <FormErrorMessage>Password is required.</FormErrorMessage>
                    )}
                  </FormControl>
                  <Stack spacing={10}>
                    <Stack
                      direction={{ base: "column", sm: "row" }}
                      align={"start"}
                      justify={"space-between"}
                    >
                      <Field as={Checkbox} name={"rememberme"}>
                        Remember me
                      </Field>
                    </Stack>
                    <Button
                      bg={"blue.400"}
                      type={"submit"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Sign in
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}
