import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { axiosInstance } from "../../../lib/axios";
import { useState } from "react";

export const SalesPerson = ({ onAdd }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [orgs, setOrgs] = useState([]);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    organization: "",
  });

  const getOrgs = async () => {
    try {
      const { data } = await axiosInstance.get("/referral/organization");
      setOrgs(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/referral/sales-persons", {
        ...formValues,
        organizationId: formValues.organization,
      });

      if (response.status === 201) {
        onClose();
        setFormValues({
          name: "",
          email: "",
          organization: "",
        });
        toast({
          title: "Sales person created.",
          description: "Sales person has been created successfully.",
          status: "success",
        });
      }

      if (response.status === 400) {
        toast({
          title: "Error",
          description: response.data.error,
          status: "error",
        });
      }
      onAdd();
    } catch (error) {
      toast({
        title: "Error",
        description: error.response.data.message || "Something went wrong",
        status: "error",
      });
    }
  }

  const openModal = () => {
    setFormValues({
      name: "",
      email: "",
      organization: "",
    });
    getOrgs();
    onOpen();
  };

  return (
    <>
      <Button onClick={openModal} size={"sm"}>
        Create Sales Person
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a Referral Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  onChange={(e) =>
                    setFormValues({ ...formValues, name: e.target.value })
                  }
                  value={formValues.name}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  onChange={(e) =>
                    setFormValues({ ...formValues, email: e.target.value })
                  }
                  value={formValues.email}
                />
                <FormHelperText>We'll never share your email.</FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Organization</FormLabel>
                <Select
                  placeholder="Select organization"
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      organization: e.target.value,
                    });
                  }}
                >
                  {orgs?.map((org) => (
                    <option key={org._id} value={org._id}>
                      {org.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button colorScheme="blue" mr={3} type="submit">
                create
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
