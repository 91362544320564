import dayjs from "dayjs";

export const formatDate = (date) => {
  if (!date) return "";

  return dayjs(date).format("DD MMM YYYY");
};

export const isPdf = (name) => name.includes(".pdf");
export const isDocx = (name) => name.includes(".docx");

export const fileUrl = (key) => {
  const fileKey = encodeURIComponent(key);
  return `${process.env.REACT_APP_BASE_URL}/CaC/download?key=${fileKey}`;
};
