import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  InputRightElement,
  useColorModeValue,
  FormErrorMessage,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { axiosInstance as axios } from "../../lib/axios";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const AddUserSchema = Yup.object().shape({
  firstName: Yup.string().required("Firstname Required"),
  lastName: Yup.string().required("LastName Required"),
  email: Yup.string()
    .email("Invalid email! Please Check Your Email Id")
    .required("Email Required"),
  password: Yup.string()
    .required("Password is Required")
    .min(8, "Password Must Contains 8 characters"),
});

export function AddAdmin({ fetch }) {
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  return (
    <Flex
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack>
        <Box rounded={"2xl"}>
          <Formik
            initialValues={{
              email: "",
              password: "",
              firstName: "",
              lastName: "",
            }}
            onSubmit={async (values) => {
              try {
                const res = await axios.post("/admin/create", {
                  ...values,
                });

                const { ok } = res.data;

                if (ok) {
                  fetch();
                  toast({
                    description: "User Created Successfully",
                    status: "success",
                    position: "top-right",
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }}
            validationSchema={AddUserSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <Stack spacing={4}>
                  <Flex gap={"8"}>
                    <FormControl
                      id="firstName"
                      isInvalid={touched.firstName && errors.firstName}
                    >
                      <FormLabel>First Name</FormLabel>
                      <Field as={Input} type="text" name="firstName" />
                      {errors.firstName && (
                        <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="lastName"
                      isInvalid={touched.lastName && errors.lastName}
                    >
                      <FormLabel>Last Name</FormLabel>
                      <Field as={Input} type="text" name="lastName" />
                      {errors.lastName && (
                        <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Flex>

                  <FormControl
                    id="email"
                    isInvalid={touched.email && errors.email}
                  >
                    <FormLabel>Email address</FormLabel>
                    <Field as={Input} type="email" name="email" />
                    {errors.email && (
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    id="password"
                    isInvalid={touched.password && errors.password}
                  >
                    <FormLabel>Password</FormLabel>

                    <InputGroup>
                      <Field
                        as={Input}
                        name={"password"}
                        type={showPassword ? "text" : "password"}
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          variant={"ghost"}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && (
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    )}
                  </FormControl>
                  <Stack spacing={10}>
                    <Button
                      bg={"blue.400"}
                      type={"submit"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                      mb={"8"}
                    >
                      Add User
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}
