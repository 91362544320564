import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { axiosInstance } from "../../../lib/axios";
import { formatDate } from "../../../lib/utils";

// {
//     "_id": "66ab369c88495d883dcf0131",
//     "user": {
//         "name": "Aftab khan",
//         "email": "loadingthelife@duck.com"
//     },
//     "plan": "Full Website with AI",
//     "status": "active",
//     "code": "AFT-CLYD9S",
//     "createdAt": "2024-08-01T07:17:48.899Z",
//     "endDate": "2025-08-09T07:17:48.890Z"
// }

export const SubList = ({ salesPersonId, name }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [subs, setSubs] = React.useState([]);

  const getSubs = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/referral/sales-persons/${salesPersonId}/subscriptions`
      );
      setSubs(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = () => {
    onOpen();
    getSubs();
  };

  return (
    <>
      <Text
        onClick={openModal}
        size={"sm"}
        _hover={{ color: "blue", cursor: "pointer" }}
      >
        {name}
      </Text>

      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"lg"}>
            Referred Users by {name} (ID: {salesPersonId})
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="simple" borderWidth="1px" mb={4}>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Referral Code</Th>
                    <Th>Plan</Th>
                    <Th>Status</Th>
                    <Th>Created At</Th>
                    <Th>End Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subs.map((sub) => (
                    <Tr key={sub._id}>
                      <Td>{sub.user.name}</Td>
                      <Td>{sub.user.email}</Td>
                      <Td>{sub.code}</Td>
                      <Td>{sub.plan}</Td>
                      <Td>{sub.status}</Td>
                      <Td>{formatDate(sub.createdAt)}</Td>
                      <Td>{formatDate(sub.endDate)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
