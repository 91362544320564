import { Box, IconButton, Input } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaUserPlus, FaDownload } from "react-icons/fa";

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  addUser,
  download,
}) => (
  <Box display={"flex"} gap={4}>
    <Input
      id="search"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      placeholder="Search"
    />
    <IconButton
      colorScheme={"linkedin"}
      onClick={onClear}
      icon={<CloseIcon />}
    />
    <IconButton
      colorScheme={"linkedin"}
      onClick={addUser}
      icon={<FaUserPlus />}
    />
    {/* export to csv */}
    <IconButton
      title="Export to CSV"
      colorScheme={"green"}
      icon={<FaDownload />}
      onClick={download}
    />
  </Box>
);

export default FilterComponent;
