import Layout from "../../../components/layouts/base.jsx";
import { useState, useMemo, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  useToast,
  Text,
  Badge,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../../lib/axios.js";
import { OrgModal } from "./org-modal.jsx";
import { SalesPerson } from "./sales-person-modal.jsx";
import { RxClipboardCopy } from "react-icons/rx";
import { OrgList } from "./org-list.jsx";
import { SubList } from "./sub-list.jsx";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "6vw",
      height: "7vh",
      color: "black",
    },
  },
};

export function ReferralPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const fetchData = useCallback(
    async (page) => {
      setLoading(true);
      const res = await axios.get(
        `/referral/sales-persons?page=${page}&limit=${perPage}`
      );
      setData(res?.data);
      setLoading(false);
    },
    [perPage]
  );

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/plans/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const toast = useToast();

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      width: "4%",
    },
    {
      name: "Name",
      cell: (_r) => <SubList name={_r.name} salesPersonId={_r._id} />,
    },
    {
      name: "Email",
      cell: (_r) => <Text whiteSpace={"nowrap"}>{_r.email}</Text>,
    },
    {
      name: "Organization",
      cell: (_r) => (
        <Text textTransform={"capitalize"} whiteSpace={"nowrap"}>
          {_r.organization?.name}
        </Text>
      ),
    },
    {
      name: "Referral Code",
      cell: (_r) => (
        <Flex>
          <Badge variant="solid" colorScheme="green">
            {_r.referralCode}
          </Badge>
          <RxClipboardCopy
            cursor={"pointer"}
            onClick={() => {
              navigator.clipboard.writeText(_r.referralCode);
              toast({
                title: "Copied to clipboard",
                status: "success",
                duration: 2000,
              });
            }}
          />
        </Flex>
      ),
    },
    // {
    //   name: "Actions",
    //   cell: (_r) => (
    //     <Box position={"fixed"} zIndex={10}>
    //       <Popover closeOnBlur={true}>
    //         <PopoverTrigger>
    //           <FaEllipsisV />
    //         </PopoverTrigger>
    //         <PopoverContent className="last_one" ml={6} minW={6}>
    //           <PopoverHeader fontWeight="semibold" textAlign={"center"}>
    //             Actions
    //           </PopoverHeader>
    //           <PopoverArrow />
    //           <PopoverCloseButton />
    //           <PopoverBody>
    //             <Stack direction={"column"}>
    //               {Actions.map((link, i) => (
    //                 <Button
    //                   key={i}
    //                   colorScheme={link.colorScheme}
    //                   onClick={() => link.onClick(_r)}
    //                   _hover={{
    //                     color: "black",
    //                   }}
    //                 >
    //                   {link.name}
    //                 </Button>
    //               ))}
    //             </Stack>
    //           </PopoverBody>
    //         </PopoverContent>
    //       </Popover>
    //     </Box>
    //   ),
    // },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <div>
          <Text fontSize="2xl" fontWeight={"extrabold"}>
            Referral
          </Text>
        </div>
        <Flex justifyContent={"center"} align={"center"} gap={4}>
          <OrgList />
          <OrgModal />
          <SalesPerson onAdd={() => fetchData(1)} />
        </Flex>
      </Box>
    );
  }, []);

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Box
          rounded={"xl"}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
            noHeader
          />
        </Box>
      </Flex>
    </Layout>
  );
}
