import Sidebar from "../admin/Sidebar";
import { get } from "../../common/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

const Main = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();

  const navigate = (url) => navigation(url);
  const toast = useToast();

  useEffect(() => {
    const activeRoute =
      location.pathname.split("/")[location.pathname.split("/").length - 1];

    if (activeRoute === "search") return setLoaded(true);
    if (location.pathname.includes("/search/results")) return setLoaded(true);

    if (
      (!get("admintoken") && !get("role")) ||
      (!get("usertoken") && !get("role"))
    ) {
      navigate("/");
      toast({
        description: "You need to login first",
        status: "error",
        position: "bottom-right",
      });
    } else {
      setLoaded(true);
    }

    if (get("role") === "user" && location.pathname.includes("admin")) {
      navigate("/");
      toast({
        description: "You need admin rights to access admin page",
        status: "error",
        position: "bottom-right",
      });
    }

    if (
      get("role") === "user" &&
      !get("user").data.haveAccessTo <= 0 &&
      !get("user").data.haveAccessTo.includes(activeRoute) &&
      activeRoute !== "search"
    ) {
      toast({
        description: "You dont have enough permission to access the page",
        status: "error",
        position: "bottom-right",
      });

      navigate("/");
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box as={"main"} fontFamily={"base"} position={"relative"}>
      {loaded ? (
        <Flex>
          <Sidebar />
          <Box as={"section"} flex={1} overflowX={"auto"}>
            {children}
          </Box>
        </Flex>
      ) : null}
    </Box>
  );
};

export default Main;
