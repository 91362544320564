import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { axiosInstance } from "../../../lib/axios";
import { useState } from "react";

export const OrgModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [orgName, setOrgName] = useState("");
  const [error, setError] = useState("");

  const createOrg = async () => {
    try {
      const response = await axiosInstance.post("/referral/organization", {
        name: orgName,
      });

      if (response.status === 201) {
        onClose();
        setOrgName("");
        toast({
          title: "Organization created.",
          description: "Organization has been created successfully.",
          status: "success",
        });
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const handleInputChange = (e) => setOrgName(e.target.value);

  return (
    <>
      <Button onClick={onOpen} size={"sm"}>
        Create Referral Org.
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a Referral Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={error}>
              <FormLabel>Name*</FormLabel>
              <Input type="text" value={orgName} onChange={handleInputChange} />
              {!error ? (
                <FormHelperText>
                  This is the name of the referral organization
                </FormHelperText>
              ) : (
                <FormErrorMessage>{error}</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={createOrg}>
              create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
