import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { axiosInstance as axios } from "../../lib/axios";
import PDFViewer from "../../components/ui/preview-pdf";
import DocxViewer from "../../components/ui/preview-docx";

import { config } from "../../common/config";

const isPdf = (url) => {
  return url.split(".").pop() === "pdf";
};

const isDocx = (url) => {
  return url.split(".").pop() === "docx";
};

const DocumentView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchDocument = async () => {
      const type = searchParams.get("type");
      if (!type) return;

      const res = await axios
        .get(`/search/find?id=${id}&type=${type}&word=""`)
        .then((res) => res.data);

      if (res.data) {
        setData(res.data);
      }
    };

    fetchDocument();
  }, [id, searchParams]);

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justifyContent={"center"}
      mx={"auto"}
      p={4}
    >
      <Flex
        direction={"column"}
        justifyContent={"center"}
        width={{
          base: "100%",
        }}
        height={"100%"}
        mb={4}
        padding={8}
        rounded={"lg"}
        boxShadow={"md"}
        borderColor={"gray.200"}
        borderWidth={2}
      >
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Text fontSize={"2xl"} maxW={"80%"}>
            {<span dangerouslySetInnerHTML={{ __html: data.name }} />}
          </Text>{" "}
          <a
            href={`${config.documentDownloadURL}/?key=${data.doc}&folder=${data.doc_path}&name=${data.name}`}
            download={data.name}
          >
            <Button fontSize={"lg"} textTransform={"capitalize"}>
              Download
            </Button>
          </a>
        </Flex>
        <Text fontSize={"lg"} color={"black.500"}>
          <span
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </Text>
        <br />
        {data.doc && (
          <Box
            color={"black.500"}
            bg={"gray.200"}
            maxH={"90vh"}
            overflow={"auto"}
            className=""
          >
            {data.docContent && (
              <span
                className="doc-content"
                dangerouslySetInnerHTML={{ __html: data.docContent }}
              />
            )}

            {data.doc &&
              (isPdf(data.doc) ? (
                <PDFViewer
                  url={`${config.documentDownloadURL}/?key=${data.doc}&folder=${data.doc_path}`}
                />
              ) : isDocx(data.doc) ? (
                <Box p={4}>
                  <DocxViewer
                    url={`${config.documentDownloadURL}/?key=${data.doc}&folder=${data.doc_path}`}
                  />
                </Box>
              ) : null)}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default DocumentView;
