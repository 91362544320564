import Layout from "../../components/layouts/base.jsx";
import { useState, useMemo, useRef, useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  IconButton,
  Textarea,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../lib/axios.js";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";

import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "10vw",
      height: "7vh",
      color: "black",
    },
  },
};

const AddFaqSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
});

export default function FaqManager() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [editorText, setEditorText] = useState("");
  const [refetch, setrefetch] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(`/faqs/paginate?page=${page}&limit=${perPage}`);
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/faqs/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });
  const [selectedFaq, setSelectedFaq] = useState({});

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };
  const deleteFaq = async (_r) => {
    const _res = await axios.delete("/faqs/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editFaq = (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();
    setEditorText(_r.docContent ? _r.docContent : "");
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editFaq,
      colorSchema: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deleteFaq,
      colorSchema: "red",
    },
  ];

  const _helpText = {
    clause: "Clause",
    contract: "Contract",
  };

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Question",
      cell: (_r) => (
        <Text
          onClick={() => {
            const videoId = new URL(_r.video).searchParams.get("v");

            caconOpen();
            setSelectedFaq({
              question: _r.question,
              answer: _r.answer,
              video: videoId,
            });
          }}
          _hover={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={"#00A0DC"}
        >
          {_r.question}
        </Text>
      ),
      sortable: true,
    },
    {
      name: "Answer",
      selector: (row, i, column) => row.answer,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (_r) => (
        <Box position={"fixed"} zIndex={10}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      onClick={() => link.onClick(_r)}
                      colorScheme={link.colorSchema}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  function resetEditor() {
    onClose();
    setCurrentOne({ edit: false, data: {} });
    setEditorText("");
  }

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Faq Manager
        </Text>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={"linkedin"}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, []);

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Modal
          initialFocusRef={initialRef}
          size={"3xl"}
          isOpen={isOpen}
          onClose={resetEditor}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? "Add New Faq" : "Edit"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody display={"flex"} flexDirection={"row"} gap={10}>
              <Box w={"100%"}>
                <Formik
                  initialValues={{
                    question: currentOne.data.question || "",
                    answer: currentOne.data.answer || "",
                    video: currentOne.data.video || "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    const url = currentOne.edit
                      ? "/faqs/" + currentOne.data._id
                      : "/faqs/create";

                    const _res = currentOne.edit
                      ? await axios.put(url, values)
                      : await axios.post(url, values);
                    if (_res.data.ok) {
                      toast({
                        description: _res.data.message,
                        status: "success",
                        position: "bottom-right",
                      });
                      resetForm();
                      setEditorText("");
                      onClose();
                      fetch();
                      setCurrentOne({
                        edit: false,
                        data: {},
                      });
                    } else {
                      toast({
                        description: _res.data.message,
                        status: "error",
                        position: "bottom-right",
                      });
                    }
                  }}
                  validationSchema={AddFaqSchema}
                >
                  {({ errors, touched, values }) => (
                    <Form>
                      <Stack spacing={4}>
                        <FormControl
                          id="question"
                          isInvalid={touched.question && errors.question}
                        >
                          <FormLabel>Question</FormLabel>
                          <Field as={Input} type="text" name="question" />
                          {errors.question && (
                            <FormErrorMessage>
                              {errors.question}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="answer"
                          name="answer"
                          isInvalid={touched.answer && errors.answer}
                        >
                          <FormLabel>Answer</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="answer"
                            value={values.answer}
                          />
                          {errors.answer && (
                            <FormErrorMessage>{errors.answer}</FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="video"
                          name="video"
                          isInvalid={touched.video && errors.video}
                        >
                          <FormLabel>Video Url</FormLabel>
                          <Field
                            as={Input}
                            type="text"
                            name="video"
                            value={values.video}
                          />
                          {errors.video && (
                            <FormErrorMessage>{errors.video}</FormErrorMessage>
                          )}
                        </FormControl>

                        <Stack spacing={10}>
                          <Button
                            colorScheme={"linkedin"}
                            type={"submit"}
                            color={"white"}
                            _hover={{ bg: "blue.500" }}
                            mb={"8"}
                          >
                            {currentOne.edit ? "Update" : "Create"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          size={"3xl"}
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedFaq({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody p={4} display={"flex"} flexDirection={"column"} gap={4}>
              <Text as={"h3"} fontSize={"2xl"}>
                Question
              </Text>
              <Text fontWeight={"normal"} fontSize={"xl"}>
                {selectedFaq.question}
              </Text>

              <Text as={"h3"} fontSize={"2xl"}>
                Answer
              </Text>
              <Text fontWeight={400} fontSize={"xl"}>
                {selectedFaq.answer}
              </Text>

              <Box>
                <Text as={"h3"} fontSize={"2xl"}>
                  Tutorial Video
                </Text>

                {selectedFaq.video ? (
                  <Box
                    mt={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${selectedFaq.video}`}
                      title={selectedFaq.question}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  </Box>
                ) : (
                  <Text>No Video</Text>
                )}
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={"linkedin"} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          // m={10}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
