import Layout from "../../components/layouts/base.jsx";
import useFetch from "../../hooks/useFetch.jsx";
import { useState, useRef, useEffect, useCallback } from "react";
import { MultiSelect } from "react-multi-select-component";
import Editor from "../../components/admin/Editor.jsx";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Text,
  IconButton,
  Textarea,
  Tooltip,
  Switch,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";

import { axiosInstance as axios, axiosInstance } from "../../lib/axios.js";
import PDFViewer from "../../components/ui/preview-pdf.jsx";
import { config } from "../../common/config.js";
import { useDebounce, useIntersectionObserver } from "@uidotdev/usehooks";
import ScrollToTop from "../../components/ui/scroll-top.jsx";
import { formatDate, isDocx, isPdf } from "../../lib/utils.js";
import PreviewDocx from "../../components/ui/preview-docx.jsx";
import { Dialog } from "primereact/dialog";
import { FaDownload } from "react-icons/fa";
import { FileUpload } from "primereact/fileupload";

const customStyles = {
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "8vw",
      height: "7vh",
      color: "black",
    },
  },
};

const LawsandRegulatrySchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  type: Yup.string().required("Type is Required"),
  country: Yup.array().required("Country is Required"),
  language: Yup.string().required("Language is Required"),
  state: Yup.array().required("State is Required"),
  similar: Yup.array().optional(),
  description: Yup.string(),
  active: Yup.boolean().default(true),
});

const DocumentType = [
  { label: "Law", value: "law" },
  { label: "Regulation", value: "regulation" },
];

const BulkUpload = () => {
  const ref = useRef(null);
  const toast = useToast();
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    axiosInstance.post("/lawsandreg/bulk-upload", formData).then((res) => {
      if (res.data.ok) {
        setFile(null);
        setVisible(false);
        toast({
          description: res.data.message,
          status: "success",
          position: "bottom-right",
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      <Button onClick={() => setVisible(true)}>Bulk Upload</Button>
      <Dialog
        header="Bulk File Upload"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <Flex gap={4}>
          Sample File
          <a href="/sample/law-upload.xlsx" download style={{ color: "blue" }}>
            <Flex gap={2} justifyContent={"center"} alignItems={"center"}>
              <FaDownload /> Download
            </Flex>
          </a>
        </Flex>

        <Flex>
          <FileUpload
            mode="basic"
            name="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            maxFileSize={1000000}
            auto={false}
            onSelect={(e) => {
              setFile(e.files[0]);
            }}
          />
        </Flex>

        <input
          type="file"
          ref={ref}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
          style={{ display: "none" }}
          accept=".xlsx, .xls, .csv"
        />
      </Dialog>
    </>
  );
};

const ExpandedComponent = ({ data }) => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });
  return (
    <div ref={ref}>
      {entry && entry.isIntersecting && (
        <div
          style={{
            height: "300px",
            width: "100%",
            overflow: "scroll",
          }}
        >
          {isPdf(data.doc) ? (
            <PDFViewer
              url={`${config.documentDownloadURL}?key=${data.doc}&folder=${data.doc_path}`}
            />
          ) : isDocx(data.doc) ? (
            <PreviewDocx
              url={`${config.documentDownloadURL}?key=${data.doc}&folder=${data.doc_path}`}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default function Lawsandregulatory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);
  const [similarLaws, setSimilarLaws] = useState([]);
  const [editorText, setEditorText] = useState("");
  const [filterText, setFilterText] = useState("");
  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLawsandReg, setSelectedLawsandReg] = useState({});
  const [seletedStates, setSelectedStates] = useState([]);
  const [institutionsList, setInstitutionsList] = useState([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);

  const [filter, setFilter] = useState({
    active: true,
  });

  const [selectedFile, changeFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedFile2, changeFile2] = useState(null);
  const initialRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });
  const [showExpanded, setShowExpanded] = useState(false);

  const { data: countries, loading: countriesLoading } =
    useFetch("/countries/");
  const { data: institutions, loading: institutionsLoading } =
    useFetch("/instituions");

  useEffect(() => {
    if (institutions) {
      setInstitutionsList(institutions.data);
      setSelectedInstitutions(institutions.data);
    }
  }, [institutions]);

  const { data: Languages, loading: LanguagesLoading } = useFetch("/language/");
  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/lawsandreg/paginate?page=${page}&limit=${perPage}`,
      {
        params: filter,
      }
    );
    setData(res.data);
    setTotalRows(res.data.total || res.data.length);
    setLoading(false);
    setCurrentPage(page);
  };

  const handleSearch = async (e, page = 1) => {
    if (!e) return;
    setLoading(true);
    const res = await axios.get(
      `/lawsandreg/search/?page=${page}&limit=${perPage}&q=${e}`
    );
    setData(res.data);
    setTotalRows(res.data.total || res.data.length);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(",");
    } else if (s === "all") {
      ids = "all";
    }
    const _res = await axios.delete(`/lawsandreg/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText("");
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (newPerPage < 100) newPerPage = 100;
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/lawsandreg/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/lawsandreg/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const lawbytype = async (type) => {
    const _res = await axios.get("/lawsandreg/getbytype/" + type);
    const data = _res.data;
    setSimilarLaws(data.data.map((r) => ({ label: r.name, value: r._id })));
  };

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };

  const deleteCategory = async (_r) => {
    const _res = await axios.delete("/lawsandreg/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editLaw = async (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();

    setEditorText(_r.docContent ? _r.docContent : "");

    const _tempCont = _r.country.map((_) => _.name).join(",");
    const res = await axios.get(`/states/${_tempCont}`);
    setSelectedStates(res.data.data);
  };

  const updateDocument = async (id, updateOptions) => {
    const res = await axios.put("/lawsandreg/update/" + id, updateOptions);
    if (res.data.ok) {
      toast({
        description: "Updated Successfully",
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const changeStatus = async (ids, status) => {
    const res = await axios.post("/lawsandreg/change-status", {
      ids,
      status,
    });

    if (res.data.ok) {
      toast({
        description: "Updated Successfully",
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const viewDocument = async (row) => {
    window.open("/admin/document/" + row._id + "?type=" + row.type, "_blank");
  };

  const Actions = [
    {
      name: "View",
      icon: FaEye,
      onClick: viewDocument,
      colorScheme: "linkedin",
    },
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editLaw,
      colorScheme: "linkedin",
    },
    {
      name: "Delete",
      icon: FaTrash,
      onClick: deleteCategory,
      colorScheme: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => {
        return currentPage === 1
          ? index + 1
          : index + 1 + perPage * (currentPage - 1);
      },
      width: "7%",
    },
    {
      name: "Name",
      cell: (row) => (
        <Tooltip label={row.name}>
          <Text
            onClick={() => {
              caconOpen();
              setSelectedLawsandReg(row);
            }}
            _hover={{ textDecoration: "underline", cursor: "pointer" }}
            color={"#00A0DC"}
            isTruncated
          >
            {row.name}
          </Text>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) =>
        row && row.country.length > 0
          ? row.country.map((e) => e.name).join(" , ")
          : "-",
    },
    {
      name: "State",
      selector: (row) =>
        row && row.state.length > 0
          ? row.state.map((e) => e.name).join(" , ")
          : "-",
    },
    {
      name: "Central",
      selector: (row) => row?.central?.name || "-",
    },
    {
      name: "Type",
      selector: (row) => (
        <Select
          placeholder="Select Type"
          onChange={(e) =>
            updateDocument(row._id, {
              type: e.target.value,
            })
          }
          defaultValue={row.type}
        >
          {DocumentType.map((e, i) => (
            <option key={i} value={e.value}>
              {e.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      name: "Last Updated",
      selector: (row) => formatDate(row.updatedAt),
    },
    {
      name: "Active",
      selector: (row) => (
        <Switch
          isChecked={row.active}
          onChange={(e) => changeStatus([row._id], e.target.checked)}
        />
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Flex gap="2">
          {Actions.map((link, i) => (
            <IconButton
              key={i}
              isRound={true}
              variant="outline"
              aria-label={link.name}
              title={link.name}
              colorScheme={link.colorScheme}
              icon={<link.icon />}
              onClick={() => link.onClick(row)}
            />
          ))}
        </Flex>
      ),
    },
  ];

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(
    () => {
      if (!debouncedSearchTerm) {
        fetchData(1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]
  );

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    values.country = values.country.map((c) => c.value);
    values.state = values.state.map((c) => c.value);
    let similarList = values.similar.map((c) => c.value);

    const formData = new FormData();
    formData.set("name", values.name);
    formData.set("type", values.type);
    formData.set("description", values.description);
    formData.set("country", values.country);
    formData.set("language", values.language);
    formData.set("state", values.state);
    formData.set("similar", similarList);
    formData.set("document", selectedFile);
    formData.set("docContent", editorText);
    formData.set("isCentral", values.isCentral);
    formData.set("institutions", values.institutions);
    formData.set("active", values.active);

    if (currentOne.edit) {
      formData.set("dontUpdateAutomatically", values.dontUpdateAutomatically);
    }

    const url = currentOne.edit
      ? "/lawsandreg/" + currentOne.data._id
      : "/lawsandreg/create";

    const _res = currentOne.edit
      ? await axios.put(url, formData)
      : await axios.post(url, formData);

    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      resetForm();
      changeFile(null);
      changeFile2(null);
      setEditorText("");
      onClose();
      fetch();
      setCurrentOne({ edit: false, data: {} });
    } else {
      setSubmitting(false);
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const BulkUploadDialog = () => {
    return (
      <Modal
        initialFocusRef={initialRef}
        isOpen={cacisOpen}
        onClose={() => {
          caconClose();
          setSelectedLawsandReg({});
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
            <Text as={"h3"}>Name</Text>
            <Text
              fontWeight={"normal"}
              style={{
                textIndent: "30px",
              }}
            >
              {selectedLawsandReg.name}
            </Text>
            <Text as={"h3"}>Type</Text>
            <Text
              fontWeight={"normal"}
              style={{
                textIndent: "30px",
              }}
            >
              {selectedLawsandReg.type}
            </Text>
            <Text as={"h3"}>Country</Text>
            <Text
              fontWeight={"normal"}
              style={{
                textIndent: "30px",
              }}
            >
              {selectedLawsandReg.country}
            </Text>
            <Text as={"h3"}>Description</Text>
            <Text
              fontWeight={"normal"}
              style={{
                textIndent: "30px",
              }}
            >
              {selectedLawsandReg.description}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme={"linkedin"} onClick={caconClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.active]);

  return (
    <Layout>
      <Flex as={"main"} bg={useColorModeValue("gray.50", "gray.800")}>
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          size={"full"}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
            setEditorText("");
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? "Add New Law or Regulatory" : "Edit"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Box>
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: currentOne.edit ? currentOne.data.name : "",
                    type: currentOne.edit ? currentOne.data.type : "",
                    description: currentOne.edit
                      ? currentOne.data.description
                      : "",
                    country: currentOne.edit
                      ? currentOne.data.country.map((c) => ({
                          label: c.name,
                          value: c.name,
                        }))
                      : [],
                    language: currentOne.edit ? currentOne?.data?.language : "",
                    state: currentOne.edit
                      ? currentOne.data.state.map((c) => ({
                          label: c.name,
                          value: c.name,
                        }))
                      : [],
                    similar: currentOne.edit ? currentOne.data.similar : [],
                    dontUpdateAutomatically: currentOne.edit
                      ? currentOne.data.dontUpdateAutomatically
                      : false,

                    isCentral: currentOne.edit
                      ? currentOne.data.central
                      : false,
                    institutions: currentOne.edit
                      ? currentOne.data.institutions || ""
                      : "",
                    active: currentOne.edit ? currentOne.data.active : true,
                  }}
                  validationSchema={LawsandRegulatrySchema}
                >
                  {({ errors, touched, values, setValues, setFieldValue }) => (
                    <Form>
                      <Stack spacing={4}>
                        <Flex
                          gap={10}
                          justifyContent={"space-between"}
                          alignItems={"start"}
                        >
                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="name"
                              isInvalid={touched.name && errors.name}
                            >
                              <FormLabel>
                                Name of the Law or Regulatory
                              </FormLabel>
                              <Field as={Input} type="text" name="name" />
                              {errors.name && (
                                <FormErrorMessage>
                                  {errors.name}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="type"
                              isInvalid={touched.type && errors.type}
                            >
                              <FormLabel>Law or Reguation</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select Type"}
                                name="type"
                                onChange={(e) => {
                                  setFieldValue("type", e.target.value);
                                  lawbytype(e.target.value);
                                }}
                              >
                                <option value="law">Law</option>
                                <option value="regulation">Regulation</option>
                              </Field>
                              {errors.type && (
                                <FormErrorMessage>
                                  {errors.type}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="language"
                              isInvalid={touched.language && errors.language}
                            >
                              <FormLabel>Language</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select language"}
                                name="language"
                              >
                                {!LanguagesLoading &&
                                  Languages.data.map((l) => (
                                    <option key={l._id} value={l._id}>
                                      {l.name}
                                    </option>
                                  ))}
                              </Field>
                              {errors.language && (
                                <FormErrorMessage>
                                  {errors.language}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="country"
                              isInvalid={touched.country && errors.country}
                              name={"country"}
                            >
                              <FormLabel>Country</FormLabel>
                              <Field
                                as={MultiSelect}
                                placeholder={"Select Country"}
                                name="country"
                                labelledBy={"Select Country"}
                                value={values.country}
                                onChange={async (e) => {
                                  try {
                                    setValues({
                                      ...values,
                                      state: [],
                                      country: e,
                                    });

                                    const _tempCont = e
                                      .map((_) => _.value)
                                      .join(",");

                                    if (!_tempCont) return;

                                    // filter institutions by country
                                    const _institutions =
                                      institutionsList.filter(
                                        (i) =>
                                          i.country.name.toLowerCase() ===
                                          e[0].value.toLowerCase()
                                      );

                                    setSelectedInstitutions(_institutions);

                                    const res = await axios.get(
                                      `/states/${_tempCont}`
                                    );
                                    setSelectedStates(res.data.data);
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                                options={
                                  !countriesLoading
                                    ? countries.data.map((e, i) => ({
                                        label: e.name,
                                        value: e.name,
                                      }))
                                    : []
                                }
                              ></Field>
                              {errors.country && (
                                <FormErrorMessage>
                                  {errors.country}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="state"
                              isDisabled={values.country === ""}
                              isInvalid={touched.state && errors.state}
                            >
                              <FormLabel>States</FormLabel>
                              <Field
                                as={MultiSelect}
                                disabled={values.country.length === 0}
                                placeholder={"Select State"}
                                name="state"
                                value={values.state}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    state: e,
                                  });
                                }}
                                options={
                                  seletedStates && seletedStates.length > 0
                                    ? seletedStates.map((e, i) => ({
                                        label: e.name,
                                        value: e.name,
                                      }))
                                    : []
                                }
                              ></Field>

                              {errors.country && (
                                <FormErrorMessage>
                                  {errors.country}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl
                              id="similar"
                              isDisabled={values.country === ""}
                              isInvalid={touched.similar && errors.similar}
                            >
                              <FormLabel>Similar</FormLabel>
                              <Field
                                as={MultiSelect}
                                placeholder={"Select Similar Law or Regulation"}
                                name="similar"
                                value={values.similar}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    similar: e,
                                  });
                                }}
                                options={
                                  similarLaws && similarLaws.length > 0
                                    ? similarLaws.map((e, i) => ({
                                        label: e.label,
                                        value: e.value,
                                      }))
                                    : []
                                }
                                disabled={similarLaws.length === 0}
                              ></Field>

                              {errors.similar && (
                                <FormErrorMessage>
                                  {errors.similar}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl id="file">
                              <FormLabel>Document</FormLabel>
                              <Input
                                type={"file"}
                                accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                                onChange={(e) => {
                                  changeFile(e.target.files[0]);
                                }}
                              />
                            </FormControl>
                          </Stack>

                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="institutions"
                              isInvalid={
                                touched.institutions && errors.institutions
                              }
                            >
                              <FormLabel>Institutions</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select Type"}
                                name="institutions"
                                onChange={(e) => {
                                  setFieldValue("institutions", e.target.value);
                                }}
                              >
                                {!institutionsLoading &&
                                  selectedInstitutions.map((l) => (
                                    <option key={l._id} value={l._id}>
                                      {l.name}
                                    </option>
                                  ))}
                              </Field>
                              {errors.institutions && (
                                <FormErrorMessage>
                                  {errors.institutions}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl
                              id="description"
                              name="description"
                              isInvalid={
                                touched.description && errors.description
                              }
                            >
                              <FormLabel>Description</FormLabel>
                              <Field
                                as={Textarea}
                                type="text"
                                name="description"
                                value={values.description}
                              />
                              {errors.description && (
                                <FormErrorMessage>
                                  {errors.description}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            {currentOne.edit && (
                              <FormControl display="flex" alignItems="center">
                                <FormLabel
                                  htmlFor="dontUpdateAutomatically"
                                  mb="0"
                                >
                                  Don't Update Automatically
                                </FormLabel>
                                <Field
                                  as={Switch}
                                  type="checkbox"
                                  id="dontUpdateAutomatically"
                                  name="dontUpdateAutomatically"
                                  isChecked={values.dontUpdateAutomatically}
                                />
                              </FormControl>
                            )}

                            <FormControl display="flex" alignItems="center">
                              <FormLabel htmlFor="isCentral" mb="0">
                                Is Central
                              </FormLabel>
                              <Field
                                as={Switch}
                                type="checkbox"
                                id="isCentral"
                                name="isCentral"
                                isChecked={values.isCentral}
                                onChange={(e) => {
                                  setFieldValue("isCentral", e.target.checked);

                                  if (e.target.checked) {
                                    setFieldValue("institutions", "");
                                    setFieldValue("state", []);
                                  }
                                }}
                              />
                            </FormControl>
                            <FormControl display="flex" alignItems="center">
                              <FormLabel htmlFor="active" mb="0">
                                Is Active
                              </FormLabel>
                              <Field
                                as={Switch}
                                type="checkbox"
                                id="active"
                                name="active"
                                isChecked={values.active}
                                onChange={(e) => {
                                  setFieldValue("active", e.target.checked);
                                }}
                              />
                            </FormControl>
                          </Stack>

                          <Stack spacing={8} width={"100%"}>
                            <Editor
                              setEditorText={setEditorText}
                              editorText={editorText}
                            />
                          </Stack>
                        </Flex>

                        <Box
                          display={"flex"}
                          gap={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Button
                            colorScheme={"linkedin"}
                            type={"submit"}
                            color={"white"}
                            _hover={{
                              bg: "blue.500",
                            }}
                            mb={"8"}
                          >
                            {currentOne.edit ? "Update" : "Create"}
                          </Button>
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <BulkUploadDialog />

        <Box
          rounded={"xl"}
          px={4}
          py={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <Flex gap="2" direction={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={"4"}
            >
              <Text
                fontSize="2xl"
                fontWeight={"extrabold"}
                whiteSpace={"nowrap"}
              >
                Laws and Regulatories
              </Text>

              <Box
                display={"flex"}
                gap={4}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                {currentSelectedRows.length > 0 && (
                  <>
                    {/* change status of multiple */}
                    <Button
                      onClick={() => {
                        changeStatus(
                          currentSelectedRows.map((r) => r._id),
                          !currentSelectedRows[0].active
                        );

                        setcurrentSelectedRows([]);
                      }}
                      colorScheme={
                        currentSelectedRows[0].active ? "yellow" : "green"
                      }
                    >
                      {currentSelectedRows[0].active
                        ? "Deactivate"
                        : "Activate"}{" "}
                      ({currentSelectedRows.length})
                    </Button>

                    <Button
                      width={"120px"}
                      colorScheme={"red"}
                      onClick={() => {
                        handleMultipleDelete(currentSelectedRows);
                      }}
                    >
                      Delete ({currentSelectedRows.length})
                    </Button>
                  </>
                )}

                <Button
                  onClick={() => {
                    showExpanded
                      ? setShowExpanded(false)
                      : setShowExpanded(true);
                  }}
                >
                  {showExpanded ? "Hide" : "Show"} Document
                </Button>

                {/* filters by active*/}
                <Select
                  value={filter.active}
                  onChange={(e) => {
                    setFilter({ ...filter, active: e.target.value });
                  }}
                  width={"200px"}
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Select>
              </Box>
            </Flex>
            <Flex gap="2">
              <Input
                flex={"1"}
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  handleSearch(search);
                }}
              >
                Search
              </Button>
              <BulkUpload visible={visible} setVisible={setVisible} />
              <Button onClick={openCreateModel}>Add New</Button>
            </Flex>
          </Flex>

          <DataTable
            columns={columns}
            data={data.data}
            paginationRowsPerPageOptions={[100, 500, 1000, 2000, 5000]}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            paginationComponentOptions={{ selectAllRowsItemText: "All" }}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows
            selectedRows={currentSelectedRows}
            onSelectedRowsChange={handleRowSelected}
            persistTableHead
            customStyles={{ ...customStyles }}
            clearSelectedRows={toggleCleared}
            expandableRows
            expandableRowsHideExpander
            expandableRowsComponent={ExpandedComponent}
            expandableRowExpanded={() => showExpanded}
          />
        </Box>
        <ScrollToTop />
      </Flex>
    </Layout>
  );
}
