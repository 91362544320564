import { Button, Flex, Input, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "../../../../lib/axios";

export const TrailDates = ({ userId, subDetails, setRefresh }) => {
  const [date, setDate] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (!subDetails) return;

    const trialEndDate = new Date(subDetails.trialEndDate)
      .toISOString()
      .split("T")[0];

    setDate(trialEndDate);

    // Check if trial has expired and subscription is active
    const currentDate = new Date().toISOString().split("T")[0];
    setIsDisabled(currentDate > trialEndDate && subDetails.status === "active");
  }, [subDetails]);

  const extendTrial = async () => {
    try {
      const res = await axios.post("/subscription/extend-trial", {
        userId,
        extendedTrialDate: date,
        subId: subDetails._id,
      });

      if (res.status === 200) {
        toast({
          description: "Trial extended successfully.",
          status: "success",
          position: "top",
        });
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      toast({
        description: "Failed to extend trial. Please try again.",
        status: "error",
        position: "top",
      });
    }
  };

  return (
    <Flex direction="column" gap={2}>
      <Flex direction="column" gap={2}>
        <Text>Trial End Date (DD/MM/YYYY):</Text>
        <Input
          type="date"
          value={date}
          min={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </Flex>

      <Button
        colorScheme="blue"
        size="sm"
        onClick={extendTrial}
        isDisabled={isDisabled}
      >
        Extend Trial
      </Button>
      {isDisabled && (
        <Text fontSize="sm" color="red.500">
          Trial extension is not available for active subscriptions with expired
          trials.
        </Text>
      )}
    </Flex>
  );
};
