import { useEffect, useState } from "react";
import { Input, Button, useToast, Flex, FormLabel } from "@chakra-ui/react";
import { axiosInstance as axios } from "../../../../lib/axios";

export const CustomLimitModal = ({ user, tabIdx }) => {
  const [lawsLimit, setLawsLimit] = useState(null);
  const [regulationsLimit, setRegulationsLimit] = useState(null);
  const [contractsLimit, setContractsLimit] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const changeLimits = async () => {
    try {
      if (!lawsLimit || !regulationsLimit || !contractsLimit) {
        toast({
          description: "Please fill all the fields.",
          status: "error",
          position: "top",
          duration: 3000,
        });
        return;
      }

      setLoading(true);
      const res = await axios.put("/subscription/update-custom-limit", {
        userId: user._id,
        lawsLimit,
        regulationsLimit,
        contractsLimit,
      });

      if (res.status === 200) {
        toast({
          description: "Limits changed successfully.",
          status: "success",
          position: "top",
        });
        setLawsLimit("");
        setRegulationsLimit("");
        setContractsLimit("");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({
        description: "Failed to change limits. Please try again.",
        status: "error",
        position: "top",
      });
    }
  };

  useEffect(() => {
    if (!user) return;
    if (tabIdx !== 2) return;

    const getCustomLimits = async () => {
      try {
        setLoading(true);
        const res = await axios
          .post("/subscription/get-sub-user", {
            userId: user._id,
          })
          .then((res) => res.data);

        if (res.status === 200) {
          const {
            planId: plan,
            customLawsDownloadLimit,
            customRegulationsDownloadLimit,
            customContractsDownloadLimit,
          } = res.data;

          if (!customLawsDownloadLimit) {
            setLawsLimit(plan.lawsDownloadLimit);
            setRegulationsLimit(plan.regulationsDownloadLimit);
            setContractsLimit(plan.contractsDownloadLimit);
          } else {
            setLawsLimit(customLawsDownloadLimit);
            setRegulationsLimit(customRegulationsDownloadLimit);
            setContractsLimit(customContractsDownloadLimit);
          }

          setLoading(false);
        }

        if (res.status === 400) {
          toast({
            description: res.message,
            status: "error",
            position: "top",
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast({
          description: "Failed to get limits. Please try again.",
          status: "error",
          position: "top",
        });
      }
    };
    getCustomLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIdx, user]);

  return (
    <Flex flexDirection="column" gap={4}>
      <FormLabel>
        Laws Download Limit
        <Input
          value={lawsLimit}
          placeholder="Laws Download Limit"
          type="number"
          onChange={(e) => setLawsLimit(e.target.value)}
        />
      </FormLabel>

      <FormLabel>
        Regulations Download Limit
        <Input
          value={regulationsLimit}
          type="number"
          placeholder="Regulations Download Limit"
          onChange={(e) => setRegulationsLimit(e.target.value)}
        />
      </FormLabel>

      <FormLabel>
        Contracts Download Limit
        <Input
          value={contractsLimit}
          type="number"
          placeholder="Contracts Download Limit"
          onChange={(e) => setContractsLimit(e.target.value)}
        />
      </FormLabel>

      <Button
        width="100%"
        colorScheme="blue"
        isLoading={loading}
        loadingText="Changing..."
        onClick={changeLimits}
      >
        Change Limits
      </Button>
    </Flex>
  );
};
