import Layout from "../../components/layouts/base.jsx";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Button,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch.jsx";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "../../lib/axios.js";
import Editor from "../../components/admin/Editor.jsx";
import "../../styles/editor.scss";

const PrivacyPolicySchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

export default function Aboutoption() {
  const [refetch, setrefetch] = useState(false);
  const [editableformcontactus, setEditableformContactus] = useState(false);
  const { data, loading } = useFetch("adminoptions/getabout", {}, refetch);
  const [editorText, setEditorText] = useState("");
  const [title, setTitle] = useState("");
  const [nowrender, setNowrender] = useState(false);

  const toast = useToast();
  const fetch = () => setrefetch(!refetch);

  useEffect(() => {
    if (!loading && data && data.data && data.data.description) {
      setTitle(data.data.title);
      setEditorText(data.data.description);
      setTimeout(() => {
        setNowrender(true);
      }, 1000);
    }
  }, [data]);

  const onSubmit = async () => {
    const res = await axios.post("/adminoptions/createabout", {
      title,
      description: editorText,
    });

    if (res.data.ok) {
      toast({
        title: "About Section Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setEditableformContactus(false);
      window.location.reload();
    } else {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Layout>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        overflowX={"hidden"}
      >
        <Flex
          width={"100%"}
          as={"main"}
          flexDirection={"column"}
          boxShadow={"md"}
          borderWidth={"1px"}
          p={5}
        >
          <Box px={6} m={6} rounded={"xl"} width={"100%"}>
            <Text as={"h1"} fontSize={"2xl"} pb={"4"}>
              Managing About Section
            </Text>

            {!loading && data && (
              <Formik
                initialValues={{
                  title:
                    !loading && data && data.data && data.data.title
                      ? data.data.title
                      : "",
                  description:
                    !loading && data && data.data && data.data.description
                      ? data.data.description
                      : "",
                }}
                onSubmit={async ({ title, description }) => {}}
                validationSchema={PrivacyPolicySchema}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <Stack spacing={8} direction={"column"} width={"100%"}>
                      <FormControl
                        id="title"
                        isInvalid={errors.title}
                        name="title"
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setFieldValue("title", e.target.value);
                        }}
                      >
                        <FormLabel>Title of the Section</FormLabel>
                        <Field as={Input} type="text" name="title" />
                        {touched.title && errors.title && (
                          <FormErrorMessage>{errors.title}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Stack>
                  </Form>
                )}
              </Formik>
            )}
          </Box>

          {nowrender ? (
            <Box px={6} m={6} height="100%">
              <Editor setEditorText={setEditorText} editorText={editorText} />
            </Box>
          ) : (
            <Box px={6} m={6} height="100%">
              <Text>Loading...</Text>
            </Box>
          )}

          <Box display={"flex"} px={6} m={6} gap={4} alignItems={"center"}>
            <Button
              bg={"blue.400"}
              colorScheme={"blue"}
              mt={4}
              type={"submit"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
              onClick={() => {
                onSubmit();
              }}
            >
              Save
            </Button>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
}
