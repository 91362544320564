import DataTable from "react-data-table-component";
import { useState, useRef, useMemo } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  IconButton,
  Input,
  useToast,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaUserPlus } from "react-icons/fa";
import { useDisclosure } from "@chakra-ui/react";
import { roles } from "../../common/modules";
import { axiosInstance as axios } from "../../lib/axios";
import { AddAdmin } from "./add-admin";

const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
  <Box display={"flex"} gap={4}>
    <Input
      id="search"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      placeholder="Search"
    />
    <IconButton
      colorScheme={"linkedin"}
      onClick={onClear}
      icon={<CloseIcon />}
    />
    <IconButton
      colorScheme={"linkedin"}
      onClick={addUser}
      icon={<FaUserPlus />}
    />
  </Box>
);

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "10.9vw",
    },
  },
};

export default function AdminList({ data: users, fetch }) {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [activeUser, setActiveUser] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: _isOpen,
    onOpen: _onOpen,
    onClose: _onClose,
  } = useDisclosure();

  const deleteUser = async (_r) => {
    const _res = await axios.delete("/admin/user/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const toast = useToast();
  const initialRef = useRef(null);

  let openModel = function (_user) {
    onOpen();

    setActiveUser(_user);
  };

  let handleAccess = function (added) {
    if (!activeUser || !activeUser.haveAccessTo) return;

    if (activeUser.haveAccessTo.includes(added)) {
      let _i = activeUser.haveAccessTo.indexOf(added);
      activeUser.haveAccessTo.splice(_i, 1);
      setActiveUser(activeUser);
    } else {
      let _temp = [...activeUser.haveAccessTo, added];
      setActiveUser({
        ...activeUser,
        haveAccessTo: _temp,
      });
    }
  };

  let addAccess = async function () {
    let res = await axios.post("/admin/addaccess", {
      email: activeUser.email,
      accessList: activeUser.haveAccessTo,
    });

    if (res.data.ok) {
      console.log(res.data);
    }
    fetch();
    onClose();
  };

  let filteredItems = users.filter(
    (item) =>
      (item.firstName &&
        item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.lastName &&
        item.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => {
        return row.mobile ? (
          <a href={"tel:" + row.mobile}>{row.mobile}</a>
        ) : (
          "N/A"
        );
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.active,
      cell: (row, i, column) => (column ? "Active" : "Not Active"),
    },
    {
      name: "Permissions",
      cell: (_r) => (
        <Box display={"flex"} gap={2}>
          <Button colorScheme={"blue"} onClick={() => openModel(_r)}>
            Edit
          </Button>
          <Button colorScheme={"red"} onClick={() => deleteUser(_r)}>
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  const openAddUserModel = () => _onOpen();

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Manage Admin
        </Text>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          addUser={openAddUserModel}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  return (
    <Box
      boxShadow="md"
      rounded={"xl"}
      p={6}
      my={4}
      backgroundColor={"#FFFFFF"}
      border={"1px"}
      borderColor={"gray.200"}
    >
      <DataTable
        striped
        columns={columns}
        data={filteredItems.length > 0 ? filteredItems : users}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 200, 500]}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        customStyles={customStyles}
      />

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          setActiveUser({});
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Access</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
            <SimpleGrid
              spacing={6}
              bg="gray.50"
              textAlign="center"
              rounded="3xl"
              columns={{ sm: 3, md: 3 }}
              color="gray.400"
            >
              {roles.roles.map((e, i) => {
                return (
                  <Checkbox
                    defaultChecked={
                      activeUser &&
                      activeUser.haveAccessTo &&
                      activeUser.haveAccessTo.includes(e)
                    }
                    onChange={() => handleAccess(e)}
                    key={i}
                    colorScheme={"green"}
                  >
                    {e}
                  </Checkbox>
                );
              })}
            </SimpleGrid>
            <Box
              h={"2px"}
              w={"100%"}
              margin={"auto"}
              backgroundColor={"#24232323"}
              borderRadius={"2xl"}
              marginTop={"1rem"}
            />
            <Box pt={10}>
              <Button>Remove User</Button>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => addAccess()}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={_isOpen} onClose={_onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Add New User</ModalHeader>
          <ModalBody display={"flex"} flexDirection={"column"}>
            <AddAdmin fetch={fetch} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
