import React, { Fragment, useCallback } from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";

import { RxFontBold } from "react-icons/rx";
import {
  RiItalic,
  RiStrikethrough,
  RiUnderline,
  RiLinksLine,
  RiMarkPenLine,
  RiH1,
  RiH2,
  RiListUnordered,
  RiListOrdered,
  RiDoubleQuotesL,
  RiSeparator,
  RiTextWrap,
  RiFormatClear,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
} from "react-icons/ri";

const MenuItem = ({ icon, title, action, isActive = null }) => (
  <button
    type="button"
    className={` menu-item${isActive && isActive() ? " is-active" : ""}`}
    onClick={action}
    title={title}
  >
    <Icon as={icon} w={6} h={6} />
  </button>
);

export const TipTapMenuBar = ({ editor }) => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }
  let items = [
    {
      icon: RxFontBold,
      title: "Bold",
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive("bold"),
    },
    {
      icon: RiItalic,
      title: "Italic",
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive("italic"),
    },
    {
      icon: RiStrikethrough,
      title: "Strike",
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive("strike"),
    },
    {
      icon: RiUnderline,
      title: "Underline",
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive("underline"),
    },
    {
      icon: RiLinksLine,
      title: "Link",
      action: () => setLink(),
      isActive: () => editor.isActive("link"),
    },
    {
      icon: RiMarkPenLine,
      title: "Highlight",
      action: () => editor.chain().focus().toggleHighlight().run(),
      isActive: () => editor.isActive("highlight"),
    },
    {
      type: "divider",
    },
    {
      icon: RiH1,
      title: "Heading 1",
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive("heading", { level: 1 }),
    },
    {
      icon: RiH2,
      title: "Heading 2",
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive("heading", { level: 2 }),
    },
    {
      icon: RiListUnordered,
      title: "Bullet List",
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive("bulletList"),
    },
    {
      icon: RiListOrdered,
      title: "Ordered List",
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive("orderedList"),
    },
    {
      type: "divider",
    },
    {
      icon: RiDoubleQuotesL,
      title: "Blockquote",
      action: () => editor.chain().focus().toggleBlockquote().run(),
      isActive: () => editor.isActive("blockquote"),
    },
    {
      icon: RiSeparator,
      title: "Horizontal Rule",
      action: () => editor.chain().focus().setHorizontalRule().run(),
    },
    {
      type: "divider",
    },
    {
      icon: RiTextWrap,
      title: "Hard Break",
      action: () => editor.chain().focus().setHardBreak().run(),
    },
    {
      icon: RiFormatClear,
      title: "Clear Format",
      action: () => editor.chain().focus().clearNodes().unsetAllMarks().run(),
    },
    {
      type: "divider",
    },
    {
      icon: RiArrowGoBackLine,
      title: "Undo",
      action: () => editor.chain().focus().undo().run(),
    },
    {
      icon: RiArrowGoForwardLine,
      title: "Redo",
      action: () => editor.chain().focus().redo().run(),
    },
  ];

  return (
    <Box display={"flex"} borderBottom={"1px solid #e2e8f0"} p={2}>
      <Flex className="editor__header" flexWrap={"wrap"}>
        {items.map((item, index) => (
          <Fragment key={index}>
            {item.type === "divider" ? (
              <Box height={"30px"} width={"1px"} bg={"#e2e8f0"} mx={2} />
            ) : (
              <MenuItem {...item} />
            )}
          </Fragment>
        ))}
      </Flex>
    </Box>
  );
};
