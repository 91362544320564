import { useState } from "react";
import Layout from "../../components/layouts/base.jsx";
import AdminList from "../../components/admin/admin-list.jsx";
import useFetch from "../../hooks/useFetch.jsx";

export default function AdminManagment() {
  const [refetch, setrefetch] = useState(false);
  const { data, loading, error } = useFetch("/admin/users", {}, refetch);
  const isLoaded = !loading && !error && data && data.data;
  const fetch = () => setrefetch(!refetch);

  return (
    <Layout>{isLoaded && <AdminList data={data.data} fetch={fetch} />}</Layout>
  );
}
