import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Badge,
  VStack,
  HStack,
  Button,
  Flex,
  Input,
  useToast,
} from "@chakra-ui/react";
import { axiosInstance as axios } from "../../../../lib/axios";

const TrailDates = ({ userId, subDetails, setRefresh, setShowExtendForm }) => {
  const [date, setDate] = useState("");
  const toast = useToast();

  useEffect(() => {
    if (!subDetails) return;

    const trialEndDate = new Date(subDetails.trialEndDate)
      .toISOString()
      .split("T")[0];

    setDate(trialEndDate);
  }, [subDetails]);

  const extendTrial = async () => {
    try {
      const res = await axios.post("/subscription/extend-trial", {
        userId,
        extendedTrialDate: date,
        subId: subDetails._id,
      });

      if (res.status === 200) {
        toast({
          description: "Trial extended successfully.",
          status: "success",
          position: "top",
        });
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      toast({
        description: "Failed to extend trial. Please try again.",
        status: "error",
        position: "top",
      });
    }
  };

  return (
    <Flex direction="column" gap={2}>
      <Flex direction="column" gap={2}>
        <Text>Trial End Date (YYYY-MM-DD):</Text>
        <Input
          type="date"
          value={date}
          min={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </Flex>

      <Button colorScheme="blue" size="sm" onClick={extendTrial}>
        Extend Trial
      </Button>
      <Button
        colorScheme="red"
        size="sm"
        onClick={() => setShowExtendForm(false)}
      >
        Cancel
      </Button>
    </Flex>
  );
};

const SubList = ({ userId }) => {
  const [subscription, setSubscription] = useState(null);
  const [showExtendForm, setShowExtendForm] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await axios.post("/subscription/get-last-sub", {
          userId,
        });
        if (res.status === 200) {
          const { data } = res.data;

          setSubscription(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getSubscription();
  }, [userId, refresh]);

  const handleShowExtendForm = () => {
    setShowExtendForm(true);
  };

  return (
    <>
      <Box mb={2}>
        <Heading size="md">
          {subscription ? "Subscription Details" : "No Subscription Found"}
        </Heading>
      </Box>

      {subscription && (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={4}
          boxShadow="md"
        >
          <VStack align="start">
            <Heading size="md">{subscription.planId.name}</Heading>
            <Badge
              colorScheme={subscription.status === "expired" ? "red" : "green"}
            >
              {subscription.status.toUpperCase()}
            </Badge>
            <HStack>
              <Text fontWeight="bold">Start Date:</Text>
              <Text>
                {new Date(subscription.startDate).toLocaleDateString()}
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">End Date:</Text>
              <Text>{new Date(subscription.endDate).toLocaleDateString()}</Text>
            </HStack>

            {subscription.status === "expired" ? (
              <>
                <Button
                  colorScheme="blue"
                  display={showExtendForm ? "none" : "block"}
                  onClick={handleShowExtendForm}
                >
                  Extend Trial
                </Button>

                {showExtendForm && (
                  <TrailDates
                    userId={userId}
                    subDetails={subscription}
                    setRefresh={setRefresh}
                    setShowExtendForm={setShowExtendForm}
                  />
                )}
              </>
            ) : null}
          </VStack>
        </Box>
      )}
    </>
  );
};

export default SubList;
