import { useEffect, useState } from "react";
import { axiosInstance as axios } from "../lib/axios";

const useFetch = (url, options, refetch) => {
  let [data, setData] = useState([]);
  let [error, setError] = useState("");
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(url, options);
        setData(data);
      } catch (error) {
        error ? setError(error.message) : setError("");
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return { data, error, loading };
};

export default useFetch;
