import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "../../../../lib/axios";
import { formatDate } from "../../../../lib/utils";
import { CustomLimitModal } from "./custom-limits-modals";
import UserEmail from "./email-modal";
import SubList from "./sub-list";
import { TrailDates } from "./trail-dates";

const BasicInfo = ({ user }) => {
  const toast = useToast();
  const s3Url = process.env.REACT_APP_S3_URL;

  const makeAdmin = async () => {
    const confirmPromotion = window.confirm(
      "Are you sure you want to promote this user to admin?"
    );
    if (!confirmPromotion) return;

    try {
      const id = user._id;
      let res = await axios.post(`/user/make-admin/${id}`);

      if (res.status === 200) {
        toast({
          description: "User is now an admin.",
          status: "success",
          position: "top",
        });
      }
    } catch (error) {
      toast({
        description:
          "An error occurred while making user an admin. Please try again.",
        status: "error",
        position: "top",
      });
    }
  };

  return (
    <Box padding="4" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem>
          <Text fontWeight="bold">First Name:</Text>
          <Text>{user.firstName}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Last Name:</Text>
          <Text>{user.lastName}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Email:</Text>
          <Text>{user.email}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Mobile:</Text>
          <Text>{user.mobile}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Role:</Text>
          <Text>{user.role}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Country:</Text>
          <Text>{user.country}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Active:</Text>
          <Text>{user.active ? "Yes" : "No"}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Created At:</Text>
          <Text>{formatDate(user.createdAt)}</Text>
        </GridItem>
        <GridItem>
          <Button colorScheme="blue" onClick={makeAdmin} size="sm">
            Promote to Admin
          </Button>
        </GridItem>
        <GridItem>
          <Button
            size={"sm"}
            as="a"
            fontWeight="bold"
            href={`${s3Url}${user.documentPath}/`}
            target="_blank"
          >
            Go to Document folder
          </Button>
        </GridItem>
      </Grid>
    </Box>
  );
};

export const UserDetailsModal = ({ isOpen, onClose, user }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [subDetails, setSubDetails] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleTabsChange = (index) => setTabIndex(index);

  useEffect(() => {
    if (isOpen) setTabIndex(0);
  }, [isOpen]);

  useEffect(() => {
    if (!user) return;

    const getSubDetails = async () => {
      const res = await axios
        .post("/subscription/get-sub-user", {
          userId: user?._id,
        })
        .then((res) => res.data);

      setSubDetails(res.data);
    };

    getSubDetails();
  }, [user, refresh]);

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>User Details</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Tabs index={tabIndex} onChange={handleTabsChange} isLazy>
            <TabList>
              <Tab whiteSpace={"nowrap"}>Basic Info</Tab>
              <Tab whiteSpace={"nowrap"}>Email</Tab>
              <Tab whiteSpace={"nowrap"}>Subscription</Tab>
              <Tab whiteSpace={"nowrap"}>Change Download Limit</Tab>
              <Tab whiteSpace={"nowrap"} isDisabled={!subDetails}>
                Change Trail Days
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <BasicInfo user={user} />
              </TabPanel>
              <TabPanel>
                <UserEmail user={user} />
              </TabPanel>
              <TabPanel>
                <SubList userId={user?._id} />
              </TabPanel>
              <TabPanel>
                <CustomLimitModal tabIdx={tabIndex} user={user} />
              </TabPanel>
              <TabPanel>
                <TrailDates
                  userId={user?._id}
                  subDetails={subDetails}
                  setRefresh={setRefresh}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
