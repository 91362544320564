import { useState } from "react";
import UsersList from "../../components/admin/user/index.jsx";
import Layout from "../../components/layouts/base.jsx";
import useFetch from "../../hooks/useFetch.jsx";

export default function UserManagment() {
  const [refetch, setrefetch] = useState(false);
  const { data, loading, error } = useFetch("user", {}, refetch);
  const isLoaded = !loading && !error && data && data.data;

  const fetch = () => setrefetch(!refetch);

  return (
    <Layout>{isLoaded && <UsersList data={data.data} fetch={fetch} />}</Layout>
  );
}
