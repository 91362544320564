import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import {
  AiFillContacts,
  AiFillDiff,
  AiFillExclamationCircle,
  AiFillFileWord,
  AiFillQuestionCircle,
  AiTwotoneCrown,
} from "react-icons/ai";
import { BiBookBookmark, BiCategoryAlt } from "react-icons/bi";
import { BsFillPenFill, BsPeople } from "react-icons/bs";
import {
  FaCookieBite,
  FaFlagUsa,
  FaHandshake,
  FaHandsHelping,
  FaIndustry,
  FaSchool,
} from "react-icons/fa";
import { FcDisclaimer } from "react-icons/fc";
import {
  FiActivity,
  FiAirplay,
  FiArchive,
  FiCheckSquare,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiClipboard,
  FiCompass,
  FiHome,
  FiLogOut,
  FiPenTool,
  FiSettings,
  FiTrendingUp,
} from "react-icons/fi";
import {
  MdMultipleStop,
  MdOutlineAddToPhotos,
  MdOutlineAdminPanelSettings,
  MdOutlineNoteAdd,
  MdOutlinePolicy,
  MdPrivacyTip,
} from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { useLocation } from "react-router";
import { Link as NavLink } from "react-router-dom";
import { wipeOut } from "../../common/storage";
import { useGlobalContext } from "../../context/GlobalContext";

export default function Sidebar({ children }) {
  const { onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.900", "gray.900")}>
      <SidebarContent onClose={() => onClose} />
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { setUser, setLoggedIn, setRole } = useGlobalContext();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [LinkItems, setLinkItems] = useState([
    { name: "Admin Management", icon: FiHome, url: "/admin/admin-managment" },
    { name: "User Management", icon: FiHome, url: "/admin/user-managment" },
    { name: "Sectors", icon: FiTrendingUp, url: "/admin/sectors" },
    { name: "Contracts and Clauses", icon: FiPenTool, url: "/admin/cac" },
    {
      name: "Laws and Regulatories",
      icon: FiClipboard,
      url: "/admin/laws-and-regulatory",
    },
    {
      name: "Judgment and Case Laws",
      icon: FaHandshake,
      url: "/admin/judgmentandcaselaws",
    },
    {
      name: "Lawyer List",
      icon: FaFlagUsa,
      url: "/admin/lawyer-list",
    },
    {
      name: "Countries",
      icon: FaFlagUsa,
      url: "/admin/countries",
    },
    {
      name: "States",
      icon: FiCompass,
      url: "/admin/states",
    },
    {
      name: "Districts",
      icon: FiCompass,
      url: "/admin/district",
    },
    {
      name: "Local",
      icon: FaHandsHelping,
      url: "/admin/local",
    },
    {
      name: "Central",
      icon: AiTwotoneCrown,
      url: "/admin/central",
    },
    {
      name: "Courts",
      icon: BsFillPenFill,
      url: "/admin/courts",
    },
    {
      name: "Judgement Category",
      icon: BiCategoryAlt,
      url: "/admin/judgementcategory",
    },
    {
      name: "Aggrement Type",
      icon: FaCookieBite,
      url: "/admin/aggrementtype",
    },
    {
      name: "Insights",
      icon: FaCookieBite,
      url: "/admin/insights",
    },
    {
      name: "Industries",
      icon: FaIndustry,
      url: "/admin/industrytype",
    },
    {
      name: "Gazzets",
      icon: AiFillFileWord,
      url: "/admin/gazzets",
    },
    {
      name: "Gazzet Types",
      icon: MdMultipleStop,
      url: "/admin/gazettestype",
    },
    {
      name: "Institutions",
      icon: FaSchool,
      url: "/admin/instituions",
    },
    {
      name: "Language",
      icon: AiTwotoneCrown,
      url: "/admin/language",
    },

    {
      name: "Plans",
      icon: AiFillDiff,
      url: "/admin/plans",
    },
    {
      name: "Members",
      icon: BsPeople,
      url: "/admin/members",
    },
    {
      name: "Important Links",
      icon: MdOutlineNoteAdd,
      url: "/admin/importantlinks",
    },
    {
      name: "Referral",
      icon: AiFillDiff,
      url: "/referral",
    },
    {
      name: "News and Legal Updates",
      icon: FiArchive,
      isOpen:
        localStorage.getItem("News and Legal Updates") === "true"
          ? true
          : false,
      children: [
        {
          name: "Library and E-Books",
          icon: BiBookBookmark,
          url: "/admin/libraryandebooks",
        },
        {
          name: "Resources",
          icon: FiArchive,
          url: "/admin/resources",
        },
      ],
    },
    {
      name: "Software",
      icon: FiArchive,
      isOpen: localStorage.getItem("Software") === "true" ? true : false,
      children: [
        {
          name: "Legal Software",
          icon: FiAirplay,
          url: "/admin/legalsoftware",
        },
        { name: "Click Rate", icon: FiActivity, url: "/admin/clickrate" },
      ],
    },
    {
      name: "Options",
      icon: FiSettings,
      isOpen: localStorage.getItem("Options") === "true" ? true : false,
      children: [
        {
          name: "About",
          icon: AiFillExclamationCircle,
          url: "/admin/aboutsec",
        },
        { name: "Contact Us", icon: AiFillContacts, url: "/admin/contactsec" },
        {
          name: "Faq Manager",
          icon: AiFillQuestionCircle,
          url: "/admin/faq",
        },
        {
          name: "Terms and Conditions",
          icon: FaHandsHelping,
          url: "/admin/terms",
        },
        {
          name: "Privacy Policy",
          icon: MdPrivacyTip,
          url: "/admin/privacy",
        },
        {
          name: "Disclaimer",
          icon: FcDisclaimer,
          url: "/admin/disclaimer",
        },
        {
          name: "careers",
          icon: SlPeople,
          url: "/admin/careers",
        },
        {
          name: "Member's Policy",
          icon: MdOutlinePolicy,
          url: "/admin/memberspolicy",
        },
      ],
    },
    {
      name: "Gallery",
      icon: MdOutlineAddToPhotos,
      url: "/admin/gallery",
    },
    {
      name: "Drafting Notes",
      icon: MdOutlineNoteAdd,
      url: "/admin/drafting",
    },
    {
      name: "Check List",
      icon: FiCheckSquare,
      url: "/admin/checklist",
    },
  ]);

  function Logout() {
    setUser(null);
    setLoggedIn(false);
    setRole("");
    wipeOut();
  }

  return (
    <Box
      transition="width 0.3s"
      bg={useColorModeValue("gray.900", "gray.900")}
      overflowY="scroll"
      display={{ base: "none", md: "block" }}
      borderRight="1px"
      color={useColorModeValue("white", "gray.100")}
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={isCollapsed ? "60px" : "250px"}
      h="100vh"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
      }}
      {...rest}
    >
      <Flex h="24" gap={4} alignItems="center" justifyContent="center">
        <Icon
          fontSize={"3xl"}
          as={MdOutlineAdminPanelSettings}
          color={useColorModeValue("white", "white")}
        />
        {!isCollapsed && (
          <Text
            fontSize="2xl"
            fontFamily={"heading"}
            fontWeight="bold"
            letterSpacing={"1.5px"}
            cursor="pointer"
          >
            Dashboard
          </Text>
        )}
        <Button
          onClick={() => setIsCollapsed(!isCollapsed)}
          size="sm"
          ml={isCollapsed ? 0 : 4}
        >
          {isCollapsed ? (
            <FiChevronRight size={48} />
          ) : (
            <FiChevronLeft size={48} />
          )}
        </Button>
      </Flex>
      <Box
        h={"2px"}
        w={"95%"}
        margin={"auto"}
        backgroundColor={"darkGray"}
        borderRadius={"2xl"}
        marginBottom={"1rem"}
      />
      {LinkItems.map((link, i) => (
        <Box key={i}>
          <Box
            onClick={() => {
              if (link.children) {
                if (link.isOpen) {
                  localStorage.removeItem(link.name);
                }
                setLinkItems(
                  LinkItems.map((item) => {
                    if (item.name === link.name) {
                      item.isOpen = !item.isOpen;
                      localStorage.setItem(link.name, item.isOpen);
                    } else {
                      item.isOpen = false;
                    }
                    return item;
                  })
                );
              }
            }}
          >
            <NavItem
              key={i}
              icon={link.icon}
              url={link.url ? link.url : "#"}
              submenu={link.children ? true : false}
              show={link.isOpen}
              isCollapsed={isCollapsed}
            >
              {link.name}
            </NavItem>
          </Box>
          <Box
            as={motion.div}
            animate={{
              opacity: link.isOpen ? 1 : 0,
              display: link.isOpen ? "block" : "none",
            }}
            exit={{ y: -20, height: 0 }}
          >
            {link.children &&
              link.children.map((child, i) => (
                <Box paddingLeft={isCollapsed ? "10px" : "30px"} key={i}>
                  <NavItem
                    key={i}
                    icon={child.icon}
                    url={child.url ? child.url : "#"}
                    isCollapsed={isCollapsed}
                  >
                    {"  " + child.name}
                  </NavItem>
                </Box>
              ))}
          </Box>
        </Box>
      ))}
      <Box
        h={"2px"}
        w={"95%"}
        margin={"auto"}
        backgroundColor={"darkGray"}
        borderRadius={"2xl"}
        marginTop={"1rem"}
      />
      <Box onClick={() => Logout()}>
        <NavItem key="Logout" icon={FiLogOut} url="/" isCollapsed={isCollapsed}>
          Logout
        </NavItem>
      </Box>
    </Box>
  );
};

const NavItem = ({
  icon,
  children,
  url,
  submenu,
  show,
  isCollapsed,
  ...rest
}) => {
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    if (location.pathname === url && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname, url]);

  return (
    <Link
      as={NavLink}
      to={url}
      color={"whatsapp.100"}
      style={{ textDecoration: "none" }}
    >
      <Flex
        align="center"
        p={isCollapsed ? "2" : "4"}
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        justifyContent={isCollapsed ? "center" : "flex-start"}
        color={location.pathname === url ? "white" : "gray.400"}
        fontWeight={location.pathname === url ? "bold" : "normal"}
        _hover={{
          bg: "lightWhite",
          color: "black",
        }}
        width="100%"
        ref={ref}
        {...rest}
        title={children}
      >
        {icon && (
          <Icon
            mr={isCollapsed ? "0" : "4"}
            fontSize="16"
            _groupHover={{
              color: "black",
            }}
            as={icon}
          />
        )}
        {!isCollapsed && children}
        {submenu && !isCollapsed && (
          <Icon
            ml="auto"
            transition="all 0.2s"
            transform={show ? "rotate(0deg)" : "rotate(-90deg)"}
            w={4}
            h={4}
            _groupHover={{
              color: "black",
            }}
            as={FiChevronDown}
          />
        )}
      </Flex>
    </Link>
  );
};
