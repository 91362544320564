import Layout from "../../components/layouts/base.jsx";
import { useState, useMemo, useRef, useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  IconButton,
  Badge,
  Select,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../lib/axios.js";
import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { MdInfoOutline } from "react-icons/md";
import Tiptap from "../../components/Tiptap/index.jsx";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "6vw",
      height: "7vh",
      color: "black",
    },
  },
};

const insightScehma = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  tags: Yup.string().required("Tags is required"),
  content: Yup.string().required("Content is required"),
});

export default function Insights() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);
  const [countries, setCountries] = useState([]);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(`/posts?page=${page}&limit=${perPage}`);
    setData(res.data);
    setTotalRows(res.data.totalPages);
    setLoading(false);
  };

  const fetchCountries = async () => {
    const res = await axios.get("/countries").then((res) => res.data);
    setCountries(res.data);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/plans/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };

  const deletePost = async (_r) => {
    const _res = await axios.delete("/posts/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editPlans = async (_r) => {
    setCurrentOne({
      edit: true,
      data: {
        ..._r,
      },
    });
    onOpen();
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editPlans,
      colorScheme: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deletePost,
      colorScheme: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      width: "4%",
    },
    {
      name: "Title",
      cell: (_r) => (
        <Text textTransform={"capitalize"} whiteSpace={"nowrap"}>
          {_r.title}
        </Text>
      ),
    },
    {
      name: "Author",
      cell: (_r) => {
        return _r.author ? (
          <Text textTransform={"capitalize"} whiteSpace={"nowrap"}>
            {_r.author?.firstName} {_r.author?.lastName}
          </Text>
        ) : (
          <Text
            textTransform={"capitalize"}
            color={"red"}
            whiteSpace={"nowrap"}
          >
            {_r.admin?.firstName} {_r.admin?.lastName}
          </Text>
        );
      },
    },
    {
      name: "Tags",
      cell: (_r) => (
        <Stack direction={"row"} spacing={2}>
          {_r.tags.map((tag, i) => (
            <Badge key={i} colorScheme="linkedin">
              {tag}
            </Badge>
          ))}
        </Stack>
      ),
    },
    {
      name: "Created At",
      cell: (_r) => (
        <Text textTransform={"capitalize"} whiteSpace={"nowrap"}>
          {new Date(_r.createdAt).toDateString()}
        </Text>
      ),
    },
    {
      name: "Actions",
      cell: (_r) => (
        <Box position={"fixed"} zIndex={10}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      colorScheme={link.colorScheme}
                      onClick={() => link.onClick(_r)}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Insights
        </Text>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={"linkedin"}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    const url = currentOne.edit ? "/posts/" + currentOne.data._id : "/posts";

    const payload = {
      ...values,
      createdBy: "admin",
    };

    const _res = currentOne.edit
      ? await axios.put(url, payload)
      : await axios.post(url, payload);

    if (_res.status === 201 || _res.status === 200) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      onClose();
      fetch();
      setCurrentOne({ edit: false, data: {} });
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.posts}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        size={"6xl"}
        onClose={() => {
          onClose();
          setCurrentOne({
            edit: false,
            data: {},
          });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {!currentOne.edit ? "Add New Insight" : "Edit"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
            <Box>
              <Formik
                initialValues={{
                  title: currentOne.data.title || "",
                  tags: currentOne.data.tags
                    ? currentOne.data?.tags?.join(",")
                    : "",
                  content: currentOne.data.content || "",
                  country: currentOne.data.country?._id || "",
                }}
                onSubmit={handleSubmit}
                validationSchema={insightScehma}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <Stack spacing={4}>
                      <Field name="title">
                        {({ field }) => (
                          <FormControl
                            isInvalid={errors.title && touched.title}
                          >
                            <FormLabel htmlFor="title">Title</FormLabel>
                            <Input {...field} id="title" placeholder="Title" />
                            <FormErrorMessage>{errors.title}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="tags">
                        {({ field }) => (
                          <FormControl isInvalid={errors.tags && touched.tags}>
                            <FormLabel
                              htmlFor="tags"
                              display={"flex"}
                              gap={2}
                              alignItems={"center"}
                            >
                              Tags
                              <Popover isLazy>
                                <PopoverTrigger>
                                  <MdInfoOutline
                                    size={24}
                                    style={{ cursor: "pointer" }}
                                  />
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverBody>
                                    <Text fontSize="small" color="gray.500">
                                      Add tags to your post to help categorize
                                      and make it discoverable.
                                    </Text>
                                    <Text
                                      fontSize="small"
                                      color="gray.500"
                                      mt={2}
                                    >
                                      Separate tags with a comma.
                                    </Text>
                                    <Text fontSize="small" color="black">
                                      Example: news, updates, events
                                    </Text>
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            </FormLabel>
                            <Input {...field} id="tags" placeholder="tags" />
                            <FormErrorMessage>{errors.tags}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="country">
                        {({ field }) => (
                          <FormControl
                            isInvalid={errors.country && touched.country}
                          >
                            <FormLabel htmlFor="country">Country</FormLabel>
                            <Select
                              {...field}
                              id="country"
                              placeholder="Select country"
                            >
                              {countries.map((country, i) => (
                                <option key={i} value={country._id}>
                                  {country.name}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {errors.country}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="content">
                        {() => (
                          <FormControl
                            isInvalid={errors.content && touched.content}
                          >
                            <FormLabel htmlFor="content">Content</FormLabel>
                            <Tiptap
                              onChange={(value) =>
                                setFieldValue("content", value)
                              }
                              content={currentOne?.data?.content || ""}
                            />
                            <FormErrorMessage>
                              {errors.content}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Button
                        colorScheme={"linkedin"}
                        type="submit"
                        isLoading={loading}
                      >
                        {currentOne.edit ? "Update" : "Create"}
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Layout>
  );
}
