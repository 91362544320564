import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../../lib/axios";
import { formatDate } from "../../../lib/utils";

import useExcelExport from "../../../hooks/useExcelExport";
import AddUser from "../AddUser";
import FilterComponent from "./_components/filter-component";
import UserActions from "./_components/user-actions";
import { UserDetailsModal } from "./_components/user-details";

export default function UsersList({ data: users, fetch }) {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const { exportToExcel } = useExcelExport();
  const toast = useToast();
  const {
    isOpen: _isOpen,
    onOpen: _onOpen,
    onClose: _onClose,
  } = useDisclosure();

  const {
    isOpen: UserDetailsIsOpen,
    onOpen: UserDetailsOnOpen,
    onClose: UserDetailsOnClose,
  } = useDisclosure();

  const deleteUser = async (_r) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (!confirm) return;

    const _res = await axios.delete("/user/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const openUserDetails = (_user) => {
    setActiveUser(_user);
    UserDetailsOnOpen();
  };

  const filteredItems = users.filter(
    (item) =>
      (item.firstName &&
        item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.lastName &&
        item.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "Name",
      cell: (row) => (
        <Text
          fontWeight={"bold"}
          fontSize={"md"}
          color={"gray.800"}
          textTransform={"capitalize"}
          onClick={() => openUserDetails(row)}
          _hover={{
            cursor: "pointer",
            color: "blue.500",
            textDecoration: "underline",
          }}
        >
          {row.firstName} {row.lastName}
        </Text>
      ),
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile || "N/A",
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Signup Date",
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
      sortFunction: (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
    },
    {
      name: "Actions",
      cell: (_r) => <UserActions user={_r} onDelete={deleteUser} />,
    },
  ];

  const openAddUserModel = () => _onOpen();

  const downloadUser = () => {
    const mappedUsers = filteredItems.length > 0 ? filteredItems : users;

    const data = mappedUsers.map((user) => {
      return {
        "First Name": user.firstName,
        "Last Name": user.lastName,
        Email: user.email,
        "Mobile Number": user.mobile,
        "Created At": formatDate(user.createdAt),
      };
    });

    exportToExcel(data, "users.xlsx");
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Manage Users ({users && users.length > 0 ? users.length : "0"})
        </Text>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          addUser={openAddUserModel}
          download={downloadUser}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  return (
    <Box border={"1px"} borderColor={"gray.200"}>
      <DataTable
        striped
        columns={columns}
        data={filteredItems.length > 0 ? filteredItems : users}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        customStyles={{
          headRow: {
            style: {
              backgroundColor: "#F9FAFB",
              borderTop: "1px solid #E5E7EB",
              borderBottom: "1px solid #E5E7EB",
            },
          },
          headCells: {
            style: {
              color: "#16161D",
              fontSize: "14px",
              fontWeight: "bold",
            },
          },
        }}
        persistTableHead
        fixedHeader
        fixedHeaderScrollHeight="85vh"
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 200, 500]}
        paginationResetDefaultPage={resetPaginationToggle}
      />

      <Modal isOpen={_isOpen} onClose={_onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Add New User</ModalHeader>
          <ModalBody display={"flex"} flexDirection={"column"}>
            <AddUser fetch={fetch} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <UserDetailsModal
        isOpen={UserDetailsIsOpen}
        onClose={UserDetailsOnClose}
        user={activeUser}
      />
    </Box>
  );
}
