import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import * as Yup from "yup";
import Layout from "../../components/layouts/base.jsx";
import { axiosInstance as axios } from "../../lib/axios.js";
import { useDebounce } from "@uidotdev/usehooks";

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};
const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  statusFilter,
  onStatusFilter,
  countryFilter,
  onCountryFilter,
  countries,
}) => (
  <Box display={"flex"} gap={2}>
    <Input
      id="search"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      placeholder="Search"
    />
    <Select value={statusFilter} onChange={onStatusFilter} width="200px">
      <option value="">Status</option>
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
      <option value="pending">Pending</option>
    </Select>
    <Select value={countryFilter} onChange={onCountryFilter} width="200px">
      <option value="">Country</option>
      {countries.map((country) => (
        <option key={country} value={country}>
          {country}
        </option>
      ))}
    </Select>
    <IconButton
      colorScheme={"linkedin"}
      onClick={onClear}
      icon={<CloseIcon />}
    />
  </Box>
);

const LawyerSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
  status: Yup.string().required("Status is required"),
  contact_info: Yup.object().shape({
    address: Yup.string(),
    telephone: Yup.string(),
    fax_number: Yup.string(),
    email: Yup.string().email("Invalid email"),
    profile_link: Yup.string().url("Invalid URL"),
    profile_desc: Yup.string(),
    profile_img: Yup.string(),
    country: Yup.string().required("Country is required"),
  }),
  job_info: Yup.object().shape({
    company_name: Yup.string(),
    job_title: Yup.string(),
    bio: Yup.string(),
    services: Yup.array().of(
      Yup.object().shape({
        service_category: Yup.string(),
        services: Yup.array().of(Yup.string()),
      })
    ),
  }),
});

export default function LawyerList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [activeLawyer, setActiveLawyer] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [statusFilter, setStatusFilter] = useState("active");
  const [countryFilter, setCountryFilter] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const toast = useToast();

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `/lawyer-list?page=${page}&limit=${perPage}&q=${debouncedSearchTerm}&status=${statusFilter}&country=${countryFilter}`
      );
      setData(res.data.data);
      setTotalRows(res.data.totalItems);
    } catch (error) {
      toast({
        description: "Error fetching lawyers",
        status: "error",
        position: "bottom-right",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [perPage, debouncedSearchTerm, statusFilter, countryFilter]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const res = await axios.get("/lawyer-list/countries");
      setCountries(res.data.data);
    } catch (error) {
      toast({
        description: "Error fetching countries",
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleBulkStatusChange = async (newStatus) => {
    // check if selected rows are empty
    if (selectedRows.length === 0) {
      toast({
        description: "No lawyers selected",
        status: "error",
        position: "bottom-right",
      });
      return;
    }
    if (
      window.confirm(
        `Are you sure you want to change the status of ${selectedRows.length} lawyers to ${newStatus}?`
      )
    ) {
      try {
        const ids = selectedRows.map((row) => row._id);
        const response = await axios.put("/lawyer-list/bulk-status-update", {
          ids,
          status: newStatus,
        });

        toast({
          description: response.data.message,
          status: "success",
          position: "bottom-right",
        });
        fetchData(1);
        setSelectedRows([]);
        // Reset the status dropdown
        const statusDropdown = document.querySelector(
          'select[name="bulkStatusChange"]'
        );
        if (statusDropdown) {
          statusDropdown.value = "";
        }
      } catch (error) {
        toast({
          description: "Error updating lawyers' status",
          status: "error",
          position: "bottom-right",
        });
      }
    }
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this lawyer?")) {
      try {
        await axios.delete(`/lawyer-list/${id}`);
        toast({
          description: "Lawyer deleted successfully",
          status: "success",
          position: "bottom-right",
        });
        fetchData(1);
      } catch (error) {
        toast({
          description: "Error deleting lawyer",
          status: "error",
          position: "bottom-right",
        });
      }
    }
  };

  const handleStatusToggle = async (lawyer) => {
    const newStatus = lawyer.status === "active" ? "inactive" : "active";
    try {
      await axios.put(`/lawyer-list/${lawyer._id}/status`, {
        status: newStatus,
      });
      toast({
        description: `Lawyer status updated to ${newStatus}`,
        status: "success",
        position: "bottom-right",
        duration: 3000,
      });
      fetchData(1);
    } catch (error) {
      toast({
        description: "Error updating lawyer status",
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const columns = [
    {
      name: "Select",
      cell: (row) => (
        <Checkbox
          isChecked={selectedRows.some((r) => r._id === row._id)}
          onChange={() => handleRowSelect(row)}
        />
      ),
      width: "50px",
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => (
        <Text>{row.type === "lawyer" ? "Lawyer" : "Law Firm"}</Text>
      ),
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row.contact_info.country,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.contact_info.email || "N/A",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Switch
          isChecked={row.status === "active"}
          onChange={() => handleStatusToggle(row)}
          colorScheme="green"
        />
      ),
      width: "100px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <Flex>
          <Button colorScheme="blue" onClick={() => handleEdit(row)} mr={2}>
            Edit
          </Button>
          <IconButton
            colorScheme="red"
            icon={<DeleteIcon />}
            onClick={() => handleDelete(row._id)}
            aria-label="Delete lawyer"
          />
        </Flex>
      ),
    },
  ];

  const handleEdit = (lawyer) => {
    setActiveLawyer(lawyer);
    onOpen();
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const url = values._id ? `/lawyer-list/${values._id}` : "/lawyer-list";
      const method = values._id ? "put" : "post";
      const res = await axios[method](url, values);

      if (res.data) {
        toast({
          description: `Lawyer ${values._id ? "updated" : "added"} successfully`,
          status: "success",
          position: "bottom-right",
        });
        resetForm();
        onClose();
        fetchData(1);
      }
    } catch (error) {
      toast({
        description: `Error ${values._id ? "updating" : "adding"} lawyer`,
        status: "error",
        position: "bottom-right",
      });
    }
    setSubmitting(false);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleCountryFilter = (e) => {
    setCountryFilter(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleRowSelect = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.some((r) => r._id === row._id)) {
        return prevSelectedRows.filter((r) => r._id !== row._id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      setSearchTerm("");
      setStatusFilter("");
      setCountryFilter("");
    };

    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        w={"100%"}
        p={2}
        flexDirection={"column"}
        gap={2}
      >
        <Flex justifyContent={"space-between"} alignItems="center">
          <Text as={"h1"} fontWeight={"bold"} fontSize={"2xl"} pb={"4"}>
            Lawyer List
          </Text>
          <Flex alignItems="center">
            <Checkbox
              isChecked={selectedRows.length === data.length}
              onChange={(e) => handleSelectAll(e.target.checked)}
              mr={2}
            >
              Select All
            </Checkbox>
            <Select
              name="bulkStatusChange"
              onChange={(e) => handleBulkStatusChange(e.target.value)}
              width="200px"
              mr={2}
            >
              <option value="">Change Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="pending">Pending</option>
            </Select>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-between"}>
          <FilterComponent
            onFilter={(e) => setSearchTerm(e.target.value)}
            onClear={handleClear}
            filterText={searchTerm}
            statusFilter={statusFilter}
            onStatusFilter={handleStatusFilter}
            countryFilter={countryFilter}
            onCountryFilter={handleCountryFilter}
            countries={countries}
          />
          <Flex>
            <Button colorScheme="blue" onClick={() => handleEdit({})}>
              Add Lawyer
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTerm,
    statusFilter,
    countryFilter,
    countries,
    selectedRows.length,
    data.length,
  ]);

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[100, 500]}
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent maxWidth="90vw">
          <ModalHeader>{activeLawyer._id ? "Edit" : "Add"} Lawyer</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              name: activeLawyer.name || "",
              type: activeLawyer.type || "",
              status: activeLawyer.status || "",
              contact_info: {
                address: activeLawyer.contact_info?.address || "",
                telephone: activeLawyer.contact_info?.telephone || "",
                fax_number: activeLawyer.contact_info?.fax_number || "",
                email: activeLawyer.contact_info?.email || "",
                profile_link: activeLawyer.contact_info?.profile_link || "",
                profile_desc: activeLawyer.contact_info?.profile_desc || "",
                profile_img: activeLawyer.contact_info?.profile_img || "",
                country: activeLawyer.contact_info?.country || "",
              },
              job_info: {
                company_name: activeLawyer.job_info?.company_name || "",
                job_title: activeLawyer.job_info?.job_title || "",
                bio: activeLawyer.job_info?.bio || "",
                services: activeLawyer.job_info?.services || [
                  { service_category: "", services: [""] },
                ],
              },
              _id: activeLawyer._id || "",
            }}
            validationSchema={LawyerSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <ModalBody>
                  <Stack spacing={6}>
                    <Flex gap={6}>
                      <Box flex={1}>
                        <Text fontWeight="bold" mb={4}>
                          Basic Information
                        </Text>
                        <Stack spacing={4}>
                          <FormControl isInvalid={errors.name && touched.name}>
                            <FormLabel>Name</FormLabel>
                            <Field as={Input} name="name" />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                          </FormControl>
                          <FormControl isInvalid={errors.type && touched.type}>
                            <FormLabel>Type</FormLabel>
                            <Field as={Select} name="type">
                              <option value="">Select Type</option>
                              <option value="lawyer">Lawyer</option>
                              <option value="lawfirm">Law Firm</option>
                            </Field>
                            <FormErrorMessage>{errors.type}</FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={errors.status && touched.status}
                          >
                            <FormLabel>Status</FormLabel>
                            <Field as={Select} name="status">
                              <option value="">Select Status</option>
                              <option value="active">Active</option>
                              <option value="pending">Pending</option>
                              <option value="inactive">Inactive</option>
                            </Field>
                            <FormErrorMessage>{errors.status}</FormErrorMessage>
                          </FormControl>
                        </Stack>
                      </Box>

                      <Box flex={1}>
                        <Text fontWeight="bold" mb={4}>
                          Contact Information
                        </Text>
                        <Stack spacing={4}>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.address &&
                              touched.contact_info?.address
                            }
                          >
                            <FormLabel>Address</FormLabel>
                            <Field as={Input} name="contact_info.address" />
                            <FormErrorMessage>
                              {errors.contact_info?.address}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.telephone &&
                              touched.contact_info?.telephone
                            }
                          >
                            <FormLabel>Telephone</FormLabel>
                            <Field as={Input} name="contact_info.telephone" />
                            <FormErrorMessage>
                              {errors.contact_info?.telephone}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.fax_number &&
                              touched.contact_info?.fax_number
                            }
                          >
                            <FormLabel>Fax Number</FormLabel>
                            <Field as={Input} name="contact_info.fax_number" />
                            <FormErrorMessage>
                              {errors.contact_info?.fax_number}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.email &&
                              touched.contact_info?.email
                            }
                          >
                            <FormLabel>Email</FormLabel>
                            <Field
                              as={Input}
                              name="contact_info.email"
                              type="email"
                            />
                            <FormErrorMessage>
                              {errors.contact_info?.email}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                      </Box>

                      <Box flex={1}>
                        <Text fontWeight="bold" mb={4}>
                          Profile Information
                        </Text>
                        <Stack spacing={4}>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.profile_link &&
                              touched.contact_info?.profile_link
                            }
                          >
                            <FormLabel>Profile Link</FormLabel>
                            <Field
                              as={Input}
                              name="contact_info.profile_link"
                            />
                            <FormErrorMessage>
                              {errors.contact_info?.profile_link}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.profile_desc &&
                              touched.contact_info?.profile_desc
                            }
                          >
                            <FormLabel>Profile Description</FormLabel>
                            <Field
                              as={Textarea}
                              name="contact_info.profile_desc"
                            />
                            <FormErrorMessage>
                              {errors.contact_info?.profile_desc}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.profile_img &&
                              touched.contact_info?.profile_img
                            }
                          >
                            <FormLabel>Profile Image URL</FormLabel>
                            <Field as={Input} name="contact_info.profile_img" />
                            <FormErrorMessage>
                              {errors.contact_info?.profile_img}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              errors.contact_info?.country &&
                              touched.contact_info?.country
                            }
                          >
                            <FormLabel>Country</FormLabel>
                            <Field as={Input} name="contact_info.country" />
                            <FormErrorMessage>
                              {errors.contact_info?.country}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                      </Box>
                    </Flex>

                    <Box>
                      <Text fontWeight="bold" mb={4}>
                        Job Information
                      </Text>
                      <Flex gap={6}>
                        <FormControl
                          flex={1}
                          isInvalid={
                            errors.job_info?.company_name &&
                            touched.job_info?.company_name
                          }
                        >
                          <FormLabel>Company Name</FormLabel>
                          <Field as={Input} name="job_info.company_name" />
                          <FormErrorMessage>
                            {errors.job_info?.company_name}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          flex={1}
                          isInvalid={
                            errors.job_info?.job_title &&
                            touched.job_info?.job_title
                          }
                        >
                          <FormLabel>Job Title</FormLabel>
                          <Field as={Input} name="job_info.job_title" />
                          <FormErrorMessage>
                            {errors.job_info?.job_title}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <FormControl
                        mt={4}
                        isInvalid={
                          errors.job_info?.bio && touched.job_info?.bio
                        }
                      >
                        <FormLabel>Bio</FormLabel>
                        <Field as={Textarea} name="job_info.bio" />
                        <FormErrorMessage>
                          {errors.job_info?.bio}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={4}>
                        Services
                      </Text>
                      {values.job_info.services.map((service, index) => (
                        <Box
                          key={index}
                          mb={4}
                          p={4}
                          borderWidth={1}
                          borderRadius="md"
                          position="relative"
                        >
                          <IconButton
                            icon={<CloseIcon />}
                            size="sm"
                            position="absolute"
                            right="8px"
                            top="8px"
                            onClick={() => {
                              const newServices = [...values.job_info.services];
                              newServices.splice(index, 1);
                              setFieldValue("job_info.services", newServices);
                            }}
                            aria-label="Remove service category"
                          />
                          <Flex gap={4} mb={2}>
                            <FormControl flex={1}>
                              <FormLabel>Service Category</FormLabel>
                              <Field
                                as={Input}
                                name={`job_info.services.${index}.service_category`}
                              />
                            </FormControl>
                            <Button
                              mt={8}
                              onClick={() => {
                                const newServices = [
                                  ...values.job_info.services,
                                ];
                                newServices[index].services.push("");
                                setFieldValue("job_info.services", newServices);
                              }}
                            >
                              Add Service
                            </Button>
                          </Flex>
                          {service.services.map((_, serviceIndex) => (
                            <FormControl key={serviceIndex} mt={2}>
                              <Flex alignItems="center">
                                <Field
                                  as={Input}
                                  name={`job_info.services.${index}.services.${serviceIndex}`}
                                  flex={1}
                                />
                                <IconButton
                                  icon={<CloseIcon />}
                                  size="sm"
                                  ml={2}
                                  onClick={() => {
                                    const newServices = [
                                      ...values.job_info.services,
                                    ];
                                    newServices[index].services.splice(
                                      serviceIndex,
                                      1
                                    );
                                    setFieldValue(
                                      "job_info.services",
                                      newServices
                                    );
                                  }}
                                  aria-label="Remove service"
                                />
                              </Flex>
                            </FormControl>
                          ))}
                        </Box>
                      ))}
                      <Button
                        onClick={() => {
                          setFieldValue("job_info.services", [
                            ...values.job_info.services,
                            { service_category: "", services: [""] },
                          ]);
                        }}
                      >
                        Add Service Category
                      </Button>
                    </Box>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" colorScheme="blue" mr={3}>
                    Save
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Layout>
  );
}
