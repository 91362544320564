import { useIntersectionObserver } from "@uidotdev/usehooks";
import DocPreview from "./preview-docx";

export const SmallDocxPreview = ({ url }) => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  return (
    <div ref={ref} id="small-preview">
      {entry && entry.isIntersecting && (
        <div
          style={{
            marginTop: "10px",
            height: "200px",
            width: "100%",
            overflowY: "scroll",
          }}
        >
          <DocPreview url={url} />
        </div>
      )}
    </div>
  );
};
